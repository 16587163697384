import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RetornoCiudades, RetornoDpto } from 'src/app/interfaces/depto.interface';
import { BusquedaService } from 'src/app/services/busqueda.service';
import { CarrocompraService } from 'src/app/services/carrocompra.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-registarse',
  templateUrl: './registarse.component.html',
  styleUrls: ['./registarse.component.css']
})
export class RegistarseComponent implements OnDestroy, OnInit {


  registrarForm: FormGroup;
  validationMessages!: { [key: string]: { [key: string]: string } };
  isloading = false;

  dptos?: RetornoDpto[];
  ciudades?: RetornoCiudades[];

  usuarioService$ :Subscription = new Subscription(); 

  @ViewChild(FormGroupDirective)
  private forDir!:FormGroupDirective;

  constructor( private fb: FormBuilder, private usuarioService: UsuarioService, private utilService: UtilService, 
               private busquedaSrv: BusquedaService, private carrocompraSrv: CarrocompraService ){

    this.registrarForm = this.fb.group({
      t_Identificacion: ['0', [Validators.required, Validators.pattern    (/^(?!0$).+/)]],
      cliente_identificacion: ['', [Validators.required]],
      cliente_tipopersona: ['0', [Validators.required, Validators.pattern(/^(?!0$).+/)]],
      prnombre: ['', [Validators.required]],
      sgdonombre: '',
      prapellido: ['', [Validators.required]],
      sgdoapellido: '',
      email: ['', [Validators.required, this.utilService.validarEmail]],
      cliente_departamento: ['0', [Validators.required, Validators.pattern(/^(?!0$).+/)]],
      cliente_ciudad: ['0', [Validators.required, Validators.pattern(/^(?!0$).+/)]],
      direccion: ['', [Validators.required, Validators.minLength(6)]],
      cliente_telefono:'',
      cliente_celular:['',[this.utilService.validaCelular]],
      fechaNac:'',
      pass:['', [Validators.required, Validators.minLength(6)]],
    });

    
    /************ MENSAJES DE VALIDACION ******************/
    this.validationMessages = {
      t_Identificacion: {
        required: 'Tipo de identificación requerida'
      },
      cliente_identificacion: {
        required: 'Identificación requerida'
      },
      prnombre: {
        required: 'El primer nombre es requerido',
      },
      prapellido: {
        required: 'El primer apellido es requerido',
      },
      email: {
        required: 'El correo electrónico es requerido',
        validaEmail: 'El correo no es válido'
      },
      direccion: {
        required: 'La dirección es requerida',
        minlength: 'La dirección debe contener al menos 6 caracteres'
      },
      cliente_celular: {
        validCel: 'El celular no es válido'
      },
      pass: {
        required: 'El password es requerido',
        minlength: 'El nombre debe tener al menos 6 caracteres'
      }

    };

  }

  ngOnInit(): void {
    this.cargarDptos();
  }



  registro(){

    const payload = {
      cliente_tipoidentificacion: this.registrarForm.get('t_Identificacion')!.value,
      cliente_tipopersona: this.registrarForm.get('cliente_tipopersona')!.value,
      cliente_identificacion: this.registrarForm.get('cliente_identificacion')!.value,
      cliente_apellido_1:this.registrarForm.get('prapellido')!.value,
      cliente_apellido_2: this.registrarForm.get('sgdonombre')?.value,
      cliente_nombre_1: this.registrarForm.get('prnombre')!.value,
      cliente_nombre_2: this.registrarForm.get('sgdonombre')?.value,
      cliente_departamento: this.registrarForm.get('cliente_departamento')!.value,
      cliente_ciudad: this.registrarForm.get('cliente_ciudad')!.value,
      usuario_email: this.registrarForm.get('email')!.value,
      cliente_direccion: this.registrarForm.get('direccion')!.value,
      cliente_telefono: this.registrarForm.get('cliente_telefono')?.value,
      cliente_celular: this.registrarForm.get('cliente_celular')!.value,
      cliente_fnacimiento: this.registrarForm.get('fechaNac')?.value||null ,
      usuario_password:this.registrarForm.get('pass')!.value
    }

    this.isloading = true;
    this.usuarioService.registro(payload).subscribe((resp: any)=>{
      this.isloading = false;
      if(resp.state){
        this.utilService.showAlert(resp.message, 'Exito!','success');
        this.forDir.resetForm();
      }
    }, (error: HttpErrorResponse)=>{
      this.isloading = false;
      console.log('error',error);
      
      if(typeof(error.error) == 'object'){
        this.utilService.showAlert(error.error.message, 'Advertencia!','warning');
      }else{
        this.utilService.showAlert(error.message, 'Error!','error');
      }
    });

  }


  getValidationMessage(field: string): string {
    console.log('field',field);
    const control = this.registrarForm.get(field);
    if (control && control.errors) {
      for (const key in control.errors) {
        if (control.errors.hasOwnProperty(key)) {
          return this.validationMessages[field][key];
        }
      }
    }
    return '';
  }


  validaCadena(event: any ){
    let value: string = event.target.value;
    let ultimoCaracter = value.charAt(value.length - 1);
        
    if( parseInt(ultimoCaracter) ){
      let res = value.slice(0, -1);
      this.registrarForm.get(event.target.name)?.setValue(res);
    }
  }

  cargarDptos(){

    const dptosLocalStorage = this.carrocompraSrv.getItem('DEPTOS_SIISA');

    if(dptosLocalStorage){
      this.dptos = dptosLocalStorage;
      return;
    }

    this.busquedaSrv.getDeptos().subscribe(resp=>{
      
      if(resp.success){
        this.dptos = resp.datos.retorno;
        this.carrocompraSrv.setItem('DEPTOS_SIISA',this.dptos);
      }

    }, (error: HttpErrorResponse)=>{
      console.log('error',error);
    });

  }

  cargarCiudadesDpto(event: Event){

    let selectElement = (event.target) as HTMLSelectElement;
    const valor = selectElement.value;
    
    if(!valor){
      return;
    } 

    this.ciudades = [];

    this.busquedaSrv.getCiudadesXdpto(valor).subscribe(resp=>{
    
      if( resp.success ){
        this.ciudades = resp.datos.retorno;
      }

    }, (error: HttpErrorResponse)=>{
      console.log('error',error);
    });
  }


  ngOnDestroy(): void {
    this.usuarioService$.unsubscribe();
  }

}