<div class="d-flex justify-content-center mx-2 mb-5">
    <form [formGroup]="registrarForm" (ngSubmit)="registro()" >
        <div class="d-flex justify-content-center flex-column align-items-center mt-3">
            <div class="d-flex justify-content-center align-items-center w-100 flex-wrap gap-2">
                <div class="d-flex flex-nowrap align-items-center">
                    <select formControlName="t_Identificacion" class="form-select input-registro mb-2">
                        <option value="0" selected disabled>Seleccione Tipo identificación</option>
                        <option value="13">Cedula de ciudadania</option>
                        <option value="21">Cedula de extranjería</option>
                        <option value="32">Tarjeta de identidad</option>
                        <option value="31">Nit</option>
                    </select>
                    <span>*</span>
                </div>

                <div class="d-flex flex-column">
                    <div class="d-flex flex-nowrap align-items-center">
                        <input formControlName="cliente_identificacion" class="form-control input-registro" type="number" placeholder="Numero de documento">
                        <span>*</span>
                    </div>
                    <div class="d-flex  justify-content-start" 
                        *ngIf="registrarForm.get('cliente_identificacion')?.invalid && (registrarForm.get('cliente_identificacion')?.dirty || registrarForm.get('cliente_identificacion')?.touched)">
                        <small class="text-danger">{{ getValidationMessage('cliente_identificacion') }}</small>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center align-items-center w-100 flex-wrap gap-2">
                <div class="d-flex flex-nowrap align-items-center">
                    <select formControlName='cliente_tipopersona' class="form-select input-registro mb-2" type="text">
                        <option value="0" selected disabled>Seleccione Tipo persona</option>
                        <option value="1">natural</option>
                        <option value="2">Juridica</option>
                    </select>
                    <span>*</span>
                </div>
                <div class="d-flex flex-column">
                    <div class="d-flex flex-nowrap align-items-center">
                        <input formControlName="prnombre" class="form-control input-registro mb-2" type="text" placeholder="Primer nombre" name="prnombre" (keyup)="validaCadena($event)">
                        <span>*</span>
                    </div>
                    <div class="d-flex  justify-content-start" 
                        *ngIf="registrarForm.get('prnombre')?.invalid && (registrarForm.get('prnombre')?.dirty || registrarForm.get('prnombre')?.touched)">
                        <small class="text-danger">{{ getValidationMessage('prnombre') }}</small>
                    </div>
                </div>
            </div>
            
            <div class="d-flex justify-content-center align-items-center w-100 flex-wrap gap-2">
                <div class="d-flex flex-nowrap">
                    <input formControlName="sgdonombre" class="form-control input-registro mb-2" type="text" placeholder="Segundo nombre" name="sgdonombre" (keyup)="validaCadena($event)">
                </div>

                <div class="d-flex flex-column">
                    <div class="d-flex align-items-center">
                        <input formControlName ="prapellido" class="form-control input-registro mb-2" type="text" name='cliente_apellido_1'  placeholder="Primer apellido" name="prapellido" (keyup)="validaCadena($event)">
                        <span>*</span>
                    </div>
                    <div class="d-flex  justify-content-start" 
                        *ngIf="registrarForm.get('prapellido')?.invalid && (registrarForm.get('prapellido')?.dirty || registrarForm.get('prapellido')?.touched)">
                        <small class="text-danger">{{ getValidationMessage('prapellido') }}</small>
                    </div>
                </div>
            </div>    
            
            <div class="d-flex justify-content-center align-items-center w-100 flex-wrap gap-2">
                <div class="d-flex flex-nowrap">
                    <div class="d-flex align-items-center">
                        <input formControlName="sgdoapellido" class="form-control input-registro mb-2" placeholder="Segundo apellido" name="sgdoapellido" (keyup)="validaCadena($event)">
                    </div>
                </div>
                <div class="d-flex flex-nowrap">
                    <select formControlName='cliente_departamento' class="form-select input-registro mb-2" type="text" (change)="cargarCiudadesDpto($event)">
                        <option value="0" selected disabled>Seleccione Departamento</option>
                        <option *ngFor="let d of dptos" [value]="d.departamento_codigo" >{{ d["departamento_nombre "] | titlecase }}</option>
                    </select>
                    <span>*</span>
                </div>
            </div>

            <div class="d-flex justify-content-center align-items-center w-100 flex-wrap gap-2">
                <div class="d-flex flex-nowrap">
                    <div class="d-flex align-items-center">
                        <select formControlName='cliente_ciudad' class="form-select input-registro" type="text">
                            <option value="0" selected disabled>Seleccione ciudad</option>
                            <option *ngFor="let c of ciudades" [value]="c.ciudad_codigo" >{{ c.ciudad_nombre| titlecase }}</option>
                        </select>
                        <span>*</span>
                    </div>
                </div>

                <div class="d-flex flex-column">
                    <div class="d-flex align-items-center">
                        <input formControlName="email" class="form-control input-registro mb-2" type="email" placeholder="Correo electronico">
                        <span>*</span>
                    </div>
                    <div class="d-flex  justify-content-start" 
                        *ngIf="registrarForm.get('email')?.invalid && (registrarForm.get('email')?.dirty || registrarForm.get('email')?.touched)">
                        <small class="text-danger">{{ getValidationMessage('email') }}</small>
                    </div>
                </div>

            </div>

            <div class="d-flex justify-content-center align-items-center w-100 flex-wrap gap-2">
                <div class="d-flex flex-column">
                    <div class="d-flex align-items-center">
                        <input formControlName='direccion' class="form-control input-registro mb-2" type="text"  placeholder="Direccion de vivienda">
                        <span>*</span>
                    </div>
                    <div class="d-flex  justify-content-start" 
                        *ngIf="registrarForm.get('direccion')?.invalid && (registrarForm.get('direccion')?.dirty || registrarForm.get('direccion')?.touched)">
                        <small class="text-danger">{{ getValidationMessage('direccion') }}</small>
                    </div>
                </div>
                <div class="d-flex flex-nowrap">
                    <input formControlName='cliente_telefono' class="form-control input-registro mb-2" type="number" placeholder="Telefono">
                </div>
            </div>

            <div class="d-flex justify-content-center align-items-center w-100 flex-wrap gap-2">
                <div class="d-flex flex-column">
                    <div class="d-flex align-items-center">
                        <input formControlName="cliente_celular" class="form-control input-registro mb-2" type="text" placeholder="Celular">
                        <span>*</span>
                    </div>
                    <div class="d-flex  justify-content-start" 
                        *ngIf="registrarForm.get('cliente_celular')?.invalid && (registrarForm.get('cliente_celular')?.dirty || registrarForm.get('cliente_celular')?.touched)">
                        <small class="text-danger">{{ getValidationMessage('cliente_celular') }}</small>
                    </div>
                </div>
                <div class="d-flex flex-nowrap">
                    <div class="d-flex flex-column">
                        <div class="d-flex flex-nowrap align-items-center">
                            <input formControlName="pass" class="form-control input-registro mb-2" type="password" placeholder="Contraseña">
                            <span>*</span>
                        </div>
                        <div class="d-flex justify-content-start"
                            *ngIf="registrarForm.get('pass')?.invalid && (registrarForm.get('pass')?.dirty || registrarForm.get('pass')?.touched)">
                            <small class="text-danger">{{ getValidationMessage('pass') }}</small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-start align-items-center w-100 flex-wrap gap-2">
               
            </div>

            <div class="d-flex justify-content-center align-items-center w-100 flex-wrap gap-2">
                <div class="d-flex justify-content-center">
                <button class="btn btn-primary btn-registrar" [disabled]=!registrarForm.valid>
                    <span *ngIf="isloading"> <app-spinner color="text-light" /> </span>
                    <span *ngIf="!isloading" style="color: white;">Registrar</span>
                </button>
                </div>
            </div> 
        </div>
    </form>
</div>