<div class="container d-flex flex-column justify-content-center">

  <div *ngIf="showLoading">
    <app-loading srcImg="../../../assets/img/alaslogo.webp" />
  </div>

  <div class="row">
      <ul class="d-flex justify-content-between align-items-center flex-wrap mt-5  gap-2">
        <li><h4>{{vin}}</h4>  </li>
      </ul>
  </div>

  <hr style="color:#481F71 !important;">

  <app-sidebar (enviarDato)="recibirDato($event)"></app-sidebar>

  <div class="container d-flex flex-column justify-content-center">

    <div class="row d-flex justify-content-center mb-5">
      <app-repuesto-card 
        titulo="Repuestos oem"
        [dataRepuestos]="nombreOem"
        (handlerVerRepuesto) = "getImagenId($event)"
        (handlerAdd) = "getRepuestos($event)"
      /> 
    </div>

    <div id="visualBloque1" >
      <div  *ngIf="busquedaPalabra.length > 0">  
        <app-repuesto-card 
          titulo="Repuestos originales"
          [dataRepuestos]="busquedaPalabra"
          (handlerVerRepuesto) = "getImagenId($event)"
          (handlerAdd) = "getRepuestos($event)"
        />
      </div>
    </div>
    
    <div id="visualBloque2">
      <app-repuesto-card 
        titulo="Repuestos originales"
        [dataRepuestos]="repuestos"
        (handlerVerRepuesto) = "getImagenId($event)"
        (handlerAdd) = "getRepuestos($event)"
      /> 
    </div>

  </div>

</div>