import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';



// Rutas
import { APP_ROUTING } from './app.routes'




//Servicios


//Componentes
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { RepuestosComponent } from './components/repuestos/repuestos.component';
import { RepuestoComponent } from './components/repuesto/repuesto.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';

import { ButtonModule } from 'primeng/button';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompraComponent } from './components/compra/compra.component';
import { CarrodecompraComponent } from './carrodecompra/carrodecompra.component';
import { SpinnerComponent } from './components/shared/spinner/spinner.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { RegistarseComponent } from './components/pages/auth/registarse/registarse.component';
import { RecodarPasswordComponent } from './components/pages/auth/recodar-password/recodar-password.component';
import { ResetPasswordComponent } from './components/pages/auth/reset-password/reset-password.component';
import { DatosdeenvioComponent } from './datosdeenvio/datosdeenvio.component';
import { FormClienteComponent } from './components/form-cliente/form-cliente.component';
import { RepuestoCardComponent } from './components/repuesto-card/repuesto-card.component';
import { SecureUrlPipe } from './pipes/secure-url.pipe';
import { LoadingComponent } from './components/shared/loading/loading.component';
import { ProgressCompraComponent } from './components/shared/progress-compra/progress-compra.component';
import { FormAgendamientoComponent } from './components/form-agendamiento/form-agendamiento.component';
import { ConfirmarComponent } from './components/confirmar/confirmar.component';
import { PayuComponent } from './components/payu/payu.component';
import { TablaResumenIvaComponent } from './components/shared/tabla-resumen-iva/tabla-resumen-iva.component';
import { TablaPedidoComponent } from './components/shared/tabla-pedido/tabla-pedido.component';
import { ConfirmacionPagoComponent } from './components/pages/pago/confirmacion-pago/confirmacion-pago.component';
/*import {LineaService} from './services/linea.service'*/

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    RepuestosComponent,
    RepuestoComponent,
    SidebarComponent,
    CompraComponent,
    CarrodecompraComponent,
    SpinnerComponent,
    CheckoutComponent,
    RegistarseComponent,
    RecodarPasswordComponent,
    ResetPasswordComponent,
    DatosdeenvioComponent,
    FormClienteComponent,
    RepuestoCardComponent,
    SecureUrlPipe,
    LoadingComponent,
    ProgressCompraComponent,
    FormAgendamientoComponent,
    ConfirmarComponent,
    PayuComponent,
    TablaResumenIvaComponent,
    TablaPedidoComponent,
    ConfirmacionPagoComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    APP_ROUTING,
    ButtonModule,
    FormsModule
    
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }      
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
