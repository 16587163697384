<div class="container">

    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col-sm"><img src="" width="120px"></div>
                <div class="col-sm"></div>
                <div class="col-sm"><img src="../../assets/img/delete_24dp.png" width="20px" alt="delete"></div>
            </div>
    

        </div>
        <div class="col">
            <p>Total:</p>
            <p class="card-text text-center"></p>
            <input type="text">
            <button  class="btn btn-promocion" >Ir a comprar</button>

        </div>
    </div>
</div>
