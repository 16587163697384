<form id="capturar" [formGroup]="form" class="flex-column gap-2 align-items-center">

  <div class="seccion-busqueda">
    
    <div class="d-flex flex-column justify-content-center align-items-center flex-wrap w-75" style="margin-top: 100px;">
      
      <div class="d-flex justify-content-center align-items-center flex-wrap my-3 gap-2 w-100 div-filtro">
        <div>
          <select class="form-select px-1" name="elegir" id="seleccionLinea" (change)="mostrarLinea()">
            <option value="placa">Búsqueda por Placa</option>
            <option value="vin"> Búsqueda por Vin</option>
            <option value="referencia">Búsqueda por Ref</option>
            <option value="linea">Búsqueda por Linea</option>
          </select>
        </div> 
        
        <div *ngIf="!verLinea">
          <input formControlName ="busqueda" type="text" id="busqueda" name="busqueda" class="form-control" required >
        </div>

        <div *ngIf="!verLinea">
          <button (click)="capturarDatos(); " class="btn btn-primary button-search" id="btn-search">Buscar</button>
        </div>
      </div>

      <div id="seccionlinea" class="container flex-column justify-content-center align-items-center" *ngIf="verLinea">
        
        <div class="form-group d-flex flex-wrap justify-content-center">
          <select class=" mb-3 select-linea control-xs" name="marca" id="" required>
            <label>Marca*</label>
            <option value="marca" >Marca</option>
            <option value="ssy">SSANYONG</option>
          </select>
    
          <select class=" mb-3 select-linea control-xs" id="modelo" (change)="obtenerFamilia()" value= '' required  >
            <option value="marca" >Modelos</option>
            <option  id="model"  *ngFor="let annoModelo of this.busqueda.anosModelo" [value]= annoModelo> {{annoModelo}} </option>
            </select>
        </div>
  
        <div class="form-group d-flex flex-wrap justify-content-center">
          <select class="select-linea mb-3 control-xs" id="familia" name="dia" (change)="obtenerIdFamilia()" value= '' required>
            <option>Familia</option>
            <option  id="familia" *ngFor="let familias of this.busqueda.familia"> {{familias}} </option>
          </select>
          
          <select class="select-linea mb-3 control-xs" id="caja" (change)="obtenerCombustible()" value= '' required >
            <option>Caja de cambios</option>
            <option id="caja" *ngFor="let cajas of this.busqueda.caja"> {{cajas}} </option>
          </select>
        </div>
    
    
        <div class="form-group d-flex flex-wrap justify-content-center">
          <select class="mb-3 select-linea control-xs" name="combustible" id="combustible" (change)="obtenerLinea()" value= '' required>
            <option >Combustible</option>
            <option id="combustible" *ngFor="let idcombustible of this.busqueda.combustible"> {{idcombustible}} </option>
          </select>

          <select class="mb-3 select-linea control-xs" name="linea" id="linea"  required >
            <option>Linea</option>
            <option id="linea" *ngFor="let linea of lineas"> {{linea}} </option>
          </select>
        </div>

        <div class="d-flex flex-wrap justify-content-center">
          <button class="btn-linea mx-5" (click)="capturarNumeroLinea()">Aceptar</button>
        </div>

      </div>

    </div>
  
  </div>
  
  <div class="mt-5">
    <div class="d-flex justify-content-center flex-column align-items-center">
      <img src="../../../assets/img/informedegarantia.png" style="width: 100%; height: auto;"  alt="garantia">
  
      <a class="boton_sc" [href]="coordinadora | secureUrl " target="_blank">Sigue tus pedidos con Coordinadora </a> 
      <!-- <button class="c_filtros">limpiar filtros</button> -->
      <br>
      <a [href]="whatsapp | secureUrl " target="_blank"  class="btn-wapp"></a>

    </div>
  </div>
  
</form>