import { Component } from '@angular/core';
import { CarrocompraService } from '../services/carrocompra.service';


@Component({
  selector: 'app-carrodecompra',
  templateUrl: './carrodecompra.component.html',
  
})
export class CarrodecompraComponent {
  prueba:any;
  constructor(

    private carrodecompraService: CarrocompraService,
    
   
   
  ) { }
  ngOnInit(): void {
    this.prueba= this.carrodecompraService.getDataProducto();
    console.log(this.prueba);
    
 
 }

datoscarrocompras(){


   console.log(this.prueba);
   
    // this.carrodecompraService.AñadirCarroCompra('precio','12300');
    // this.carrodecompraService.AñadirCarroCompra('nombre','oddie');
    // this.carrodecompraService.AñadirCarroCompra('precio','19300');


  }
}
