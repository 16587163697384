import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { catchError, of } from 'rxjs';
import { FiltrosService } from 'src/app/services/filtros.service';
import { BusquedaService } from 'src/app/services/busqueda.service';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent {

  onEventoHijo!: Function;
  selectedFunction?: string; // Para almacenar el nombre de la función seleccionada

  @ViewChild('sideBar') sideBar!: ElementRef; 

  @Output() funcionFiltro = new EventEmitter<{ tipo: string, valor: string }>();
  obj: any;
  
  grupo = [
    {
      id:"flexRadioDefault1",
      value:"01",
      name:"CARROCERIA"
    },
    {
      id:"flexRadioDefault2",
      value:"02",
      name:"CHASIS"
    },
    {
      id:"flexRadioDefault3",
      value:"03",
      name:"EQUIPO ELECTRICO TRANMISION DE POTENCIA"
    },
    {
      id:"flexRadioDefault4",
      value:"04",
      name:"HERRAMIENTAS Y ACCESORIOS"
    },
    {
      id:"flexRadioDefault5",
      value:"05",
      name:"INTERIORES Y EXTERIORES"
    },
    {
      id:"flexRadioDefault6",
      value:"06",
      name:"MOTOR"
    },
    {
      id:"flexRadioDefault7",
      value:"07",
      name:"SISTEMA AIRE ACONDICIONADO"
    },
    {
      id:"flexRadioDefault8",
      value:"08",
      name:"SISTEMA COMBUSTIBLE Y CONTROL MOTOR"
    },
    {
      id:"flexRadioDefault9",
      value:"09",
      name:"TRANSMISION DE POTENCIA"
    },
  ];

  isUnique: any;
  subgrupos: any;
  subgr: any = [];
  subgruposValue: any[] = [];
  productos: any = [];
  busquedaPalabra: any;
  precio: any[] = [];
  arraySinDuplicados: any[] = [];
  imagenes: any[] = [];
  palabra: any;
  dato: any;
  datoPalabra: any[] = [];
  linea?: any;
  numeroLinea?: any;
  seleccionados?: any;
  seleccionado?: any;
  subgrupo?: any;
  nLinea?: any;

  @Input() busquedaPalabras: any;
  @Output() enviarDato = new EventEmitter<any>();
  seleccionadopr?: [];
  loading = false;


  constructor(private filtroService: FiltrosService, private busquedaService: BusquedaService, 
             private router: ActivatedRoute, private renderer: Renderer2) {
    this.router.params.subscribe(params => {

      this.nLinea = (params['inputbusqueda']);
    });
  }

  ngOnInit(): void {
    this.seleccionadopr = [];
  }

  
  buscarPalabra() {
    const palabras = (<HTMLInputElement>document.getElementById('palabraSearch')).value;
    this.palabra = palabras;
    this.numeroLinea = this.palabra
    this.enviarDato.emit(this.numeroLinea);
    this.busquedaService.setPalabra(this.palabra);
  }

  
  envioPalabra() {
    this.buscarPalabra();
  }

  
  obtenerValorSeleccionadoGrupo(): void {
    const seleccionados: string[] = [];
    this.arraySinDuplicados = [];

    const radiosSeleccionados = document.querySelectorAll<HTMLInputElement>('input[name="opcion"]:checked');
    radiosSeleccionados.forEach(function (radio) {
      seleccionados.push(radio.value);
    });

    if (seleccionados.length > 0) {

      this.seleccionado = seleccionados.join(", ");
      this.loading = true;
      this.filtroService.getValueSubgrupo(this.seleccionado)
        .pipe(
          catchError((error) => {
            console.error('Error al obtener los datos:', error);
            this.loading = false;
            return of([]);
          })
        )
        .subscribe((response: any) => {
          this.loading = false;
          if (response && response.success === 'true') {

            this.subgr = response.datos.retorno.map(
              (elemento: any) =>
                elemento);
            this.subgr.sort((a: any, b: any) => {
              if (a.referencia_subgruporepuesto_d < b.referencia_subgruporepuesto_d) {
                return -1;
              }
              if (a.referencia_subgruporepuesto_d > b.referencia_subgruporepuesto_d) {
                return 1;
              }
              return 0;
            });
            
            this.arraySinDuplicados = this.eliminarDuplicados(this.subgr);

          } else {
            console.error('La solicitud a la API no fue exitosa');
          }
        });

      this.ObtenerProducto();
    }
  }


  eliminarDuplicados(array: any) {
    const unicos: any[] = [];
    const uniqueObj: { [key: string]: boolean } = {};

    array.forEach((obj: { referencia_subgruporepuesto_d: any; referencia_subgruporepuesto: any; }) => {

      const key = obj.referencia_subgruporepuesto_d + obj.referencia_subgruporepuesto;

      if (!uniqueObj[key]) {
        uniqueObj[key] = true;
        unicos.push(obj);
      }
    });

    return unicos;
  }


  ObtenerProducto() {

    var seleccionadospr: string[] = [];

    const radiosSeleccionados = document.querySelectorAll<HTMLInputElement>('input[name="opciones"]:checked');
    radiosSeleccionados.forEach(function (radio) {
      seleccionadospr.push(radio.value);
    });

    if (seleccionadospr.length > 0) {

      var seleccionadopr = seleccionadospr.join(", ");
      
      this.filtroService.getProducto(seleccionadopr, this.seleccionado, this.nLinea)
        .pipe(
          catchError((error) => {
            console.error('Error al obtener los datos:', error);
            return of([]); // Devuelve un observable vacío en caso de error
          })
        )
        .subscribe((response: any) => {
          if (response && response.success === 'true') {
            this.productos = response.datos.retorno.map(
              (elemento: any) =>
                elemento
            ).filter((nombre: any, indice: any, array: string | any[]) => array.indexOf(nombre) === indice);
            this.filtroService.setSubgrupoSeleccionado(this.productos);

          } else {
            console.error('La solicitud a la API no fue exitosa');
          }
        });
    }

    this.RepuestoFinal();

  }


  RepuestoFinal() {
    var seleccionadospr: string[] = [];

    const radiosSeleccionados = document.querySelectorAll<HTMLInputElement>('input[name="opcionesrepuesto"]:checked');
    radiosSeleccionados.forEach(function (radio) {
      seleccionadospr.push(radio.value);
    });

    if (seleccionadospr.length > 0) {
      var seleccionadopr = seleccionadospr.join(", ");
      console.log(seleccionadopr);
      this.numeroLinea = seleccionadopr;
      this.enviarDato.emit(this.numeroLinea);
    }
  }

  
  onkeyUpSearch(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      this.envioPalabra();
      const sideBarElement  = this.sideBar.nativeElement;
      const backdrop        = document.querySelector('.offcanvas-backdrop');
      const body            = document.querySelector('body');
      this.renderer.removeClass(sideBarElement,'show');
      backdrop?.remove();
      body?.removeAttribute('style');
      
    }
  }

}
