<div class="container d-flex flex-column justify-content-center mb-5">

  <div *ngIf="showLoading">
    <app-loading srcImg="../../../assets/img/alaslogo.webp" />
  </div>
  
  <div class="row">
    <ul class="d-flex justify-content-between align-items-center flex-wrap mt-5  gap-2">
      <li><h4>{{ getVin | async }}</h4></li>
    </ul>
  </div>
  
  <hr style="color:#481F71 !important;">


  <div class="container ">
  
    <div class="d-flex justify-content-center align-items-center m-5 flex-wrap gap-3">
      <div class="div-img-repuesto">
        <img [src]=this.producto.imagen class="img-repuesto" alt="imagen repuesto" width="280px" height="210px">
      </div>

      <div class="card rounded" style="max-width: 450px;min-width: 350px;">
        <div class="card-header">
          <strong>{{this.producto.nombre}}</strong>
        </div>

        <div class="card-body justify-content-center">
          
          <div class="d-flex align-items-center justify-content-center gap-2 pt-3">
            <p class="card-text text-center precio_producto ms-5"> 
              <strong> $ {{this.producto.precio| number:'1.0-0'}}</strong>
            </p>
            <p><strong>Iva incluido</strong></p>
          </div>

          <div>
            <p class="text-center" [ngStyle]="{'color': cantDisponible > 0  ? 'green' : 'red'}">Disponible: {{ getDisponible }} </p>
          </div>

          <div class="d-flex justify-content-center align-items-center div-cant" >
            <button class=" btn boton-cantidad" [value]="inputCantidad" (click)="disminuirCantidad()">-</button>
            <input type="number" id="contador" [value]="inputCantidad" class="input-cantidad" value="0" readonly>
            <button class=" btn boton-cantidad" (click)="aumentarCantidad()">+</button>
          </div>

          <div name="hora" class="d-flex justify-content-center pt-3">
            <button class="button-añadir" style="margin-left: 10px;" (click)="anadirCarrito(this.producto)">
              <img src="../../../assets/img/carritodecompras.webp" alt="logocarrocompra" height="18px">
              Añadir
            </button>
          </div>

          <div class="mt-3">
            <p class="img-repuesto-truck"><img src="../../../assets/img/Truck.png" alt="logocarrocompra" height="16px">
              <strong> Compra mínima de {{vlrCompraMinima | currency:'USD':'symbol':'1.0-0' }}.</strong>
              <br>
              <strong>{{envioGratis}}</strong>
            </p>
          </div>

        </div>
      </div>
  
    </div>
    
  </div>

</div>