<div class="container">

    <div class="row ">
        
        <div class="col-sm">
        

        
        </div>


        <div class="col-sm">
        <div class="row form-compra">
            <div class="col "> <select id="ciudad"  name="ciudad" class="form-control"  onchange= "actualizarRazonSocial()">Tipo de documento
                <option  value="bogota">Cedula</option>
                <option  value="bucaramanga">Pasaporte</option>
                <option  value="cali">Cali </option>
                <option  value="medellin">MedellÃ­n</option>
               
             </select>
       </div>
            <div class="col">
                <input type="number" class="form-control" >
            </div>
    </div>
            
        
        </div>
    </div>

   
</div>
