import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { catchError, firstValueFrom, of, Subscription } from 'rxjs';
import { BusquedaService } from 'src/app/services/busqueda.service';
import { FiltrosService } from 'src/app/services/filtros.service';
import { Producto } from 'src/app/models/producto.models';
import { CarrocompraService } from 'src/app/services/carrocompra.service';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/services/auth.services';
CarrocompraService


@Component({
  selector: 'app-repuestos',
  templateUrl: './repuestos.component.html',

})

export class RepuestosComponent implements OnInit, OnDestroy {

  getReferencia$: Subscription = new Subscription();
  getPalabra$: Subscription = new Subscription();
  getLinea$: Subscription = new Subscription();
  getOem$: Subscription = new Subscription();
  addCarritoDB$: Subscription = new Subscription();
  updateItemCarritoBD$: Subscription = new Subscription();
  getProductosBD$: Subscription = new Subscription();

  //repuestos: Producto = new Producto();
  repuestos: any = [];
  imagen: string = '';
  nombre: string = '';
  precio: string = '';
  productosnew: any[] = [];
  showLoading: boolean = false;
  cantItemCarrito = 0;

  linea: any[] = [];
  vin: any[] = [];
  placa: any[] = [];
  producto: any;
  busquedaPalabra: any = [];
  nombreConLinea: any[] = [];
  nombreOem: any[] = [];
  inputBusqueda!: any;
  numeroLinea!: any;
  noLinea: any;
  jsonData: any;
  data: any;
  datahome: any;
  valorPropiedad: any;
  atosArreglo: any;
  subgrupos: any[] = [];
  dataPalabra: any[] = [];
  palabra: any;
  url: any;
  productos: any;
  datoEnviado: any;
  datoRecibido?: any;
  visualBloque1?: any;
  visualBloque2?: any;
  idrepuesto: any;
  url_imagen?: any;
  busquedaPalabranombre: any;
  
  
  constructor(private http: HttpClient, private busquedaService: BusquedaService, private filtroService: FiltrosService, private router: ActivatedRoute,
    private routers: Router, private carrodecompraService: CarrocompraService, private utilService: UtilService, private authService: AuthService) {

    this.url = 'https://siisarepuestos.s3-us-west-2.amazonaws.com/TV/';


    this.router.params.subscribe(params => {

      this.numeroLinea = (params['inputbusqueda']);
    })

    this.noLinea = this.numeroLinea;

  }

  ngOnInit(): void {

    console.log('RepuestosComponent');

    this.obtenerNombreConLinea();
    this.obtenerOem();
    this.datoNumeroLinea();
    this.obtenerProducto()
    this.visualBloque1 = <HTMLSelectElement>(document.getElementById('visualBloque1'));
    this.visualBloque2 = <HTMLSelectElement>(document.getElementById('visualBloque2'));
    this.getProductosCarritoDB();
  }

  recibirDato(dato: any) {
    this.datoRecibido = dato;
    this.datoEnviado = dato;
    this.datoRecibido = !/^[0-9]+$/.test(dato)
    // this.obtenerPalabra();

    if (this.datoRecibido) {
      this.obtenerPalabra();

    }
    else {
      this.obtenerReferencia();

    }
    console.log(this.datoRecibido);
  }

  obtenerReferencia() {
    this.visualBloque2.style.display = 'block';
    this.visualBloque1.style.display = 'none';

    this.getReferencia$ = this.busquedaService.getReferencia(this.datoEnviado)
      .pipe(
        catchError(error => {
          console.error('Error al obtener los datos:', error);
          return of([]);
        })
      )
      .subscribe((response: any) => {
        if (response && response.success === 'true') {

          console.log('response',response);

          const data = [{
            id : response.datos.referencia_codigo,
            referencia_nombre : response.datos.referencia_nombre,
            url_imagen : this.url + response.datos.referencia_imagen,
            referencia_codigo : response.datos.referencia_codigo,
            referencia_preciovigente : response.datos.referencia_preciovigente
          }];
          this.inputBusqueda = this.repuestos.referencia_codigo;

          this.repuestos = data;

        }
        else {
          console.error('La solicitud a la API no fue exitosa');

        }
      });

  }

  datoNumeroLinea() {
    this.busquedaService.setNumerolinea(this.noLinea);
  }

  // funcion para activar y desactivar el loading
  mostrarLoading() {
    this.showLoading = true;
  }

  ocultarLoading() {
    this.showLoading = false;
  }

  obtenerPalabra() {
    this.visualBloque2.style.display = 'none';
    this.visualBloque1.style.display = 'block';
    this.mostrarLoading();
    this.getPalabra$ = this.busquedaService.getPalabra(this.datoEnviado)
      .pipe(
        catchError((error) => {
          console.error('Error al obtener los datos:', error);
          return of([]);
        })
      )
      .subscribe((response: any) => {
        if (response && response.success === 'true') {
          this.ocultarLoading()
          this.busquedaPalabra = response.datos.retorno.map((elemento: any) =>
            elemento).filter((nombre: any, indice: any, array: string | any[]) => array.indexOf(nombre) === indice);

          this.busquedaPalabranombre = response.datos.retorno.map((elemento: any) =>
            elemento.referencia_nombre).filter((nombre: any, indice: any, array: string | any[]) => array.indexOf(nombre) === indice);

          if( this.busquedaPalabranombre.length == 0 ){
            this.utilService.showAlert('No se encontraron repuestos con la busqueda solicitada','Advertencia!','warning');
          }

          // const nuevoProducto = {
          //   nombre: this.busquedaPalabra.referencia_nombre,
          //   precio: this.busquedaPalabra.referencia_preciovigentesiniva
          // };
          // this.carrodecompraService.AñadirCarroCompra(nuevoProducto);   

        } else {
          console.error('La solicitud a la API no fue exitosa');
        }
      });
  }



  obtenerNombreConLinea() {

    this.mostrarLoading();
    this.getLinea$ = this.busquedaService.getLinea(this.numeroLinea)
      .pipe(
        catchError((error) => {
          console.error("Error al obtener los datos:", error);
          return of([]);
        })
      )
      .subscribe((response: any) => {
        if (response && response.success === "true") {
          this.ocultarLoading()
          this.vin = response.datos.retorno.map(
            (elemento: { linecome_descripcomercial: any }) =>
              elemento.linecome_descripcomercial
          ).filter((nombre: any, indice: any, array: string | any[]) => array.indexOf(nombre) === indice);

          this.busquedaService.setVin(this.vin);
        }
        else {
          console.error('La solicitud a la API no fue exitosa');
          this.ocultarLoading();
        }

      });
  }



  obtenerOem() {

    this.getOem$ = this.busquedaService.getOem(this.numeroLinea)
      .pipe(
        catchError((error) => {
          console.error("Error al obtener los datos:", error);
          return of([]); // Devuelve un observable vacío en caso de error
        })
      )
      .subscribe((response: any) => {
        if (response && response.success === "true") {
          this.nombreOem = response.datos.retorno.map(
            (elemento: any) =>
              elemento
          );
          this.inputBusqueda = this.nombreOem;
        }
        else {
          console.error('La solicitud a la API no fue exitosa');
        }
      });
  }


  async getRepuestos(producto: any) {
    //getRepuestos(id:any,imagen:any, nombre:any, precio:any){
    
    if (typeof (producto) != 'object') {
      this.utilService.showAlert('Repuesto no válido', 'Advertencia!', 'warning');
      return;
    }

    const nuevoProducto = {
      id: producto.referencia_codigo,
      cantidad: 1,
      caracteristicas: producto.referencia_caracteristica,
      codigo: producto.referencia_codigo,
      descripcion: producto.linecome_descripcomercial,
      imagen: producto.url_imagen,
      iva: producto.referencia_iva,
      marca: producto.referencia_marca,
      nombre: producto.referencia_nombre,
      precio: producto.referencia_preciovigente,
      preciosiniva: producto.referencia_preciovigentesiniva
    };

    const data = await this.getDisponible(producto.referencia_codigo);
    
    if(data == null){
      this.utilService.showAlert('El repuesto no se encuentra disponible.','Advertencia!','warning');
      return;
    }
   
    if (this.authService.isLoggedIn()) {
      this.buscarItemCarritoDB(nuevoProducto);
    } else {
      this.addCarritoLocal(nuevoProducto);
    }

  }

  async getDisponible(payload: string){
    let disponible: any;
    let cantidadServ: any;
    let filter: any;
    try {

      let productosCarrito = [];
      disponible = await firstValueFrom(this.busquedaService.getReferencia(payload));
      cantidadServ  = disponible.datos.refebode_disponible;
      
      if(cantidadServ== 0){
        return null;
      }

      if(this.authService.isLoggedIn()){
        productosCarrito = this.productosnew;
      }else{
        productosCarrito = this.carrodecompraService.getItem('carrito');
        if(productosCarrito == null){
          productosCarrito = [];
        }
      }
      
      if(productosCarrito.length > 0){
        filter = productosCarrito.filter((resp:any)=>resp.id == payload);
        if(filter.length == 0){
          return cantidadServ;
        }

        const dif = cantidadServ - filter[0].cantidad;
        return dif > 0 ? dif : null;

      }else{
        return cantidadServ;
      }

    } catch (error) {
      return null; 
    }
   
  }

  addCarritoLocal(payload: any) {
    this.carrodecompraService.AñadirCarroCompra(payload);
    this.utilService.showAlert('Has añadido un respuesto a tu carro de compras', 'Exito!', 'success');
  }

  /************ BUSCAMOS EL REPUESTO SI YA ESTA ADICIONADO EN EL CARRITO DE BASE DE DATOS ******************* */
  buscarItemCarritoDB(producto: any) {
    let cantidad = 0;
    let item: any = this.productosnew.filter((resp: any) => {
      if (resp.id == producto.id) {
        cantidad = resp.cantidad + producto.cantidad;
        return resp;
      }
    });

    //si existe actualizamos la cantidad
    if (item.length > 0) {
      producto = { ...producto, cantidad };
      this.updateCarritoDb(producto);
    } else {
      this.addCarritoDb(producto);
    }
  }

  /************* ADICIONAR ITEMS AL CARRITO BASE DE DATOS ******************* */
  addCarritoDb(producto: any) {
    const usuario = this.authService.getUsuario();

    if (usuario == null) {
      this.utilService.showAlert('No hay un usuario válido en sesión.', 'Advertencia!', 'warning');
      return;
    }
    this.mostrarLoading();

    const payload = [{
      carcomp_cliente: usuario.data.usuario_cliente,
      carcomp_producto: producto.codigo,
      carcomp_cantidad: 1,
      carcomp_precio: producto.precio,
      carcomp_prodnomb: producto.nombre,
      carcomp_imagen: producto.imagen,
      //carcomp_tamanno: 0,
      carcomp_proddescrip: producto.descripcion,
      carcomp_marca: producto.marca,
      carcomp_caracteristicas: producto.caracteristicas,
      carcomp_presiniva: producto.preciosiniva,
      carcomp_iva: 0,
      carcomp_restriccion: '',
      carcomp_unidadminima: 1,
      carcomp_estado: 'AC'
    }];

    this.addCarritoDB$ = this.carrodecompraService.addCarritoDB(payload).subscribe((resp: any) => {
      this.ocultarLoading();

      if (resp.state) {
        this.utilService.showAlert('Has añadido un respuesto a tu carro de compras', 'Exito!', 'success');
        this.productosnew = [...this.productosnew, producto];
        this.updateCantidadItemsCarrito(this.productosnew );
      }


    }, (error: HttpErrorResponse) => {
      console.log('error', error);
      this.ocultarLoading();
      if (typeof (error.error) == 'object') {
        this.utilService.showAlert(error.error.message, 'Error!', 'error');
      } else {
        this.utilService.showAlert(error.message, 'Error!', 'error');
      }
    });

  }

  /***************** ACTUALIZAMOS ITEM CARRITO BASE DE DATOS ************************************* */
  updateCarritoDb(producto: any){
    const usuario = this.authService.getUsuario();

    if (usuario == null) {
      this.utilService.showAlert('No hay un usuario válido en sesión.', 'Advertencia!', 'warning');
      return;
    }
    
    const item = {
      carcomp_cliente: usuario.data.usuario_cliente,
      carcomp_producto: producto.codigo,
      carcomp_cantidad: producto.cantidad,
      carcomp_precio: producto.precio,
      carcomp_prodnomb: producto.nombre,
      carcomp_imagen: producto.imagen,
      //carcomp_tamanno: 0,
      carcomp_proddescrip: producto.descripcion,
      carcomp_marca: producto.marca,
      carcomp_caracteristicas: producto.caracteristicas,
      carcomp_presiniva: producto.preciosiniva,
      carcomp_iva: 0,
      carcomp_restriccion: '',
      carcomp_unidadminima: 1,
      carcomp_estado: 'AC'
    };
    
    const payload = {
      id: producto.id,
      cliente: usuario.data.usuario_cliente,
      producto: item
    }
    
    this.mostrarLoading();
    this.updateItemCarritoBD$ = this.carrodecompraService.updateItemCarritoBD(payload).subscribe((resp: any)=>{
      this.ocultarLoading();
      if(resp.state){
        this.utilService.showAlert('Has añadido un respuesto a tu carro de compras', 'Exito!', 'success');
          this.productosnew = this.productosnew.map((resp: any)=>{
          if(resp.id == producto.id){
            return {...resp, cantidad: producto.cantidad};
          }
          return resp;
        });
      }

      this.updateCantidadItemsCarrito(this.productosnew);

    }, (error: HttpErrorResponse)=>{
      this.ocultarLoading();
      console.log('error',error);
      if(typeof(error.error) != 'object' ){
        this.utilService.showAlert(error.message,'Error!','error');
      }else{
        this.utilService.showAlert(error.error.message,'Error!','error');
      }
    });
  }


  getImagenId(id: any) {
    this.inputBusqueda = id;
    this.routers.navigate(['/repuesto', this.inputBusqueda, ]);
  }


  obtenerProducto() {
    this.productos = this.filtroService.setSubgrupoSeleccionado(this.producto);
    console.log(this.productos);

  }


  /******* OBTENER LOS PRODUCTOS DEL CARRITO DEL CLIENTE DE LA BASE DE DATOS *******/
  getProductosCarritoDB() {
    const usuario = this.authService.getUsuario();

    if (usuario == null) {
      return;
    }

    const payload = usuario.data.usuario_cliente;

    this.getProductosBD$ = this.carrodecompraService.getProductosBD(payload).subscribe((resp: any) => {
      if(resp.state){
        this.productosnew = resp.data;
      }

    }, (error: HttpErrorResponse) => {

      console.log('error', error);
      let msg = '';
      if (typeof (error.error) != 'object') {
        msg = error.message;
        this.utilService.showAlert(msg, 'Error!', 'error');
      }
    });
  }


  updateCantidadItemsCarrito(productos: any){
    this.carrodecompraService.updateCantidadItemsCarrito(productos);
 }



  /********** CERRAMOS TODAS LAS SUBSCRIPCIONES************ */
  ngOnDestroy(): void {
    if( this.getReferencia$ ){
       this.getReferencia$.unsubscribe(); 
    }
    if( this.getPalabra$  ){
       this.getPalabra$.unsubscribe(); 
    }
    if( this.getLinea$  ){
       this.getLinea$.unsubscribe(); 
    }
    if( this.getOem$  ){
       this.getOem$.unsubscribe(); 
    }
    if( this.addCarritoDB$  ){
       this.addCarritoDB$.unsubscribe(); 
    }
    if( this.updateItemCarritoBD$  ){
       this.updateItemCarritoBD$.unsubscribe(); 
    }
    if( this.getProductosBD$  ){
       this.getProductosBD$.unsubscribe(); 
    }
  }

}