 
 
 
 
 <a class="btn btn-primary btn-filtro" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
    aria-controls="offcanvasExample">
    <img src="../../../../assets/img/filtro_1.svg" style="width:32px; height: 20px; margin-left: -12px;" alt="filtro">
    Filtrar
  </a>


  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" #sideBar>
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExampleLabel">Filtra tu repuesto</h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div class="input-group flex-nowrap">
        
        <input type="text" class="form-control" id="palabraSearch" placeholder="Busqueda por palabra" aria-label="Username"
          aria-describedby="addon-wrapping" (keyup)="onkeyUpSearch($event)">
          <br>
      <button class="c_Search"  (click)="envioPalabra()" aria-label="Close" data-bs-dismiss="offcanvas">Buscar</button>
      </div>

      <div class="dropdown mt-3 filtros">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                aria-expanded="true" aria-controls="collapseOne">
                <img src="../../../../assets/img/iconogrupos.png" style="padding: 12px;" alt="grupos"> Grupos
              </button>
            </h2>
            
            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div id="formulario"  (change)="obtenerValorSeleccionadoGrupo()">
                  <div class="form-check" *ngFor="let grup of grupo" >
                    <input class="form-check-input" type="radio" name="opcion" id="{{grup.id}}" value="{{grup.value}}"   data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <label class="form-check-label" for="{{grup.id}}">
                      {{grup.name | titlecase }}  
                    </label>
                  </div>
              </div>
            </div>

          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <img src="../../../../assets/img/iconosubgrupos.png" style="padding: 12px;" alt="subgrupos">
                <p>Sub grupos</p>
              </button>
            </h2>

            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <strong>Selecciona un grupo</strong>

                  <div *ngIf="loading" class="d-flex justify-content-center mt-2">
                    <app-spinner />
                  </div>
                
                  <div class="formulario" *ngIf="arraySinDuplicados.length > 0; else subgrupoEmpty" >
                    <div class="form-check" *ngFor="let subgr of arraySinDuplicados "  >
                      <input class="form-check-input" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"  type="radio" name="opciones" id="flexRadioDefault2{{ subgr }}" value=  {{subgr.referencia_subgruporepuesto}}  (change)="obtenerValorSeleccionadoGrupo()">
                      <label class="form-check-label" >
                        {{ subgr.referencia_subgruporepuesto_d | titlecase }}
                      </label>
                    </div>
                  </div>

                  <ng-template #subgrupoEmpty>
                    <p class="text-danger" >No existen subgrupos</p>
                  </ng-template>
              </div>

            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <img src="../../../../assets/img/iconoproductos.png" style="padding: 12px;" alt="productos"> Productos
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <strong>Selecciona un Subgrupo</strong>
                
                <div *ngIf="loading" class="d-flex justify-content-center mt-2">
                  <app-spinner />
                </div>
                
                <div class="formulario" *ngIf="productos.length > 0; else productosEmpty" >
                  <div class="form-check" *ngFor="let productos of productos "  >
                    <input class="form-check-input"   type="radio" name="opcionesrepuesto" aria-label="Close" data-bs-dismiss="offcanvas" id="flexRadioDefault3{{ productos.referencia_codigo }}" value=  {{productos.referencia_codigo}}  (change)="RepuestoFinal()">
                    <label class="form-check-label" >
                      {{ productos.referencia_nombre | titlecase }}
                    </label>
                  </div>
                </div>

                <ng-template #productosEmpty>
                  <p class="text-danger" >No existen productos</p>
                </ng-template>
              </div>
            </div>

          </div>
          
        </div>
      </div>
      <br>
    </div>
    
