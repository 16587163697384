import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICiudades, IDepartamento } from '../interfaces/depto.interface';
import { IAgendamiento, IResponseAgendamiento } from '../interfaces/agendamiento.interface';

@Injectable({
  providedIn: 'root'
})
export class BusquedaService {
  private key = environment.SIISA_KEY;
  private apiUrl  = environment.API_SIISA_TV;
  private apiUrl2 =  environment.API_SIISA_TVC;
  private URL_INDICAR =  environment.API_INDICAR;
  public palabra : any;
  public noLinea: any;
  public datosPalabra = [{busquedaPalabra:''}]

  private vin = new BehaviorSubject([]);
  vin$ = this.vin.asObservable();

  constructor(private httpClient: HttpClient,) { }

  setDatosPalabra(busquedaPalabra:any){

    this.datosPalabra.push(busquedaPalabra);
  }

  getDatosPalabra(){
    return this.datosPalabra;
  }
  setPalabra(palabra:string){
  
    this.palabra=palabra;
   console.log(palabra);
    
  }

  setNumerolinea(noLinea:string){
  
    this.noLinea=noLinea;
   console.log(noLinea);
    
  }
  getDatos(){
    return this.palabra;
  }
  
    getPlaca(inputBusqueda: string) {
      const data = {
        p_access: this.key,
        action: '_get_consultavinplaca',
        placa: inputBusqueda,
        
      }

      
      
      const params = new HttpParams({ fromObject: data });
      return this.httpClient.get<any>(this.apiUrl, { params });



    }
    

  getVin(inputBusqueda: string) {
    const data = {
      p_access: this.key,
      action: '_get_consultavinplaca',   
      vin: inputBusqueda,
     
    }
   
    const params = new HttpParams({ fromObject: data });
    return this.httpClient.get<any>(this.apiUrl, { params });
  


  }
 

  getReferencia(inputBusqueda: string) {
    const data = {
      p_access: this.key,
      action: '_get_disponible',      
      referencia: inputBusqueda,
    }
    
    console.log('getReferencia',data);
    
    const params = new HttpParams({ fromObject: data });
    return this.httpClient.get<any>(this.apiUrl2, { params });

  }

  
  
  
  
  

  getLinea(numeroLinea:string){
    const data ={
      p_access: this.key,
      action:'getReferencia_Catalogo',
      tipo: 1,
      marca: "SSY",
      linea: numeroLinea

    }

    const params =new HttpParams({ fromObject:data});
    return this.httpClient.get<any>(this.apiUrl, { params });
  }

  getPalabra(datoRecibido:any){

    const data={
      p_access:this.key,
      action:"getReferencia_Catalogo",
      tipo: 1,
      marca:'SSY',
      linea: this.noLinea,
      referencia: datoRecibido     
  
  
    }
     console.log(data);
     
    
    const params = new HttpParams ({ fromObject:data});
      return this.httpClient.get <any> (this.apiUrl, {params})
   }
  

  getOem(numeroLinea:string){
    const data={
      p_access:this.key,
      action:'getReferencia_Catalogo',
      tipo: 1,
      marca: "SSY",
      linea: numeroLinea,
      referencia: 'oem'

      }
     
      const params =new HttpParams({ fromObject:data});
      return this.httpClient.get<any>(this.apiUrl, { params });
  }

  setVin(vin: any){
    this.vin.next(vin);
  }

  getDeptos():Observable<IDepartamento>{
    const URL = this.apiUrl;
    const params = new HttpParams()
        .set('p_access', this.key)
        .set('action', 'getDepartamento');
    return this.httpClient.get<IDepartamento>(URL, {params});
  }

  getCiudadesXdpto(dpto: string):Observable<ICiudades>{
    const URL = this.apiUrl;
    const params = new HttpParams()
        .set('p_access', this.key)
        .set('action', 'getCiudad')
        .set('dpto', dpto);
    return this.httpClient.get<ICiudades>(URL, {params});
  }
  
  
  agendar(payload: IAgendamiento):Observable<IResponseAgendamiento>{

    //TODO:ENDPOINT PARA CAMPAÑA DE TABLA PARAMETROS
    const URL = this.URL_INDICAR;
    const params = new HttpParams()
        .set('_p_action', 'webpage_ssangyong')
        .set('p_origen', 'organica-ssy')
        .set('p_campanna', 1479)
        .set('p_tipo', 'agendamientos');
    return this.httpClient.post<IResponseAgendamiento>(URL, payload, {params});
  }
  
  /***************** VALIDAR DISPONIBILIDAD ******************************************** */
  getDisponibilidad(payload: string){
    
    const URL = this.apiUrl2;

    const params = new HttpParams()
      .set('p_access', this.key)
      .set('action', '_get_validaDisponibilidad')
      .set('referencia', payload);

    return  firstValueFrom (this.httpClient.get(URL, {params}) );
  }

}
