import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { AuthService } from 'src/app/services/auth.services';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CarrocompraService } from 'src/app/services/carrocompra.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { BusquedaService } from 'src/app/services/busqueda.service';
import { UtilService } from 'src/app/services/util.service';




@Component({
  selector: 'app-confirmar',
  templateUrl: './confirmar.component.html',
  styleUrls: ['./confirmar.component.css']
})
export class ConfirmarComponent implements OnInit  {



  carritoItems$ = new Subscription(); 
  getProductosBD$ = new Subscription(); 
  listaRepuestos:any = [];
  showLoading = false;
  msg_entrega = false;
  frase = environment.FRASE_TIENDA;
  title_tiempo_entrega = environment.TITLE_TIEMPO_ENTREGA;
  msg_tiempo_entrega = environment.MSG_TIEMPO_ENTREGA;
  terminos = false;
  deshabilitar = true;
  msgProductoNoDisponible = '';
  urlTYC = environment.TYC;

  @ViewChild('chkConfirmar') chkConfirmar!: ElementRef 

  
  iva = environment.IVA;
  ivaplus = environment.IVAPLUS;

  //PAYU
  apiKey = environment.APIKEY;
  merchantId = environment.MERCHANTID;
  currency = environment.CURRENCY;
  signature = '';
  referencePago = '';


  constructor(private location: Location, private authSrv: AuthService, private router: Router, private carritoSrv: CarrocompraService, 
             private busquedaSrv: BusquedaService, private utilSrv: UtilService) { }
  
  logged = this.authSrv.islogIn$;

  
  ngOnInit(): void {
    this.carritoSrv.removeLocalStore('carritoTmp');
    this.isLogin();
    this.listarRepuestos();
    this.carritoItems$ = this.carritoSrv.itemsCarrito$.subscribe((items)=>{
      this.listaRepuestos = items;  
    });

   
  }

  atras() {
    this.location.back();
  }

  isLogin(){

    const logIn = this.authSrv.isLoggedIn();

    if( !logIn ){

      this.router.navigate(['/home']);
    }

    console.log('logIn',logIn);

  }

  listarRepuestos(){
    const usuario = this.authSrv.getUsuario();

    if(usuario == null){
      this.listaRepuestosLocalStorage();
    }else{
      this.listaRepuestosBD(usuario);
    }
  }


  listaRepuestosLocalStorage(){
    this.carritoSrv.setItemsCarritoBD(this.carritoSrv.getRepuestos())
  }

  listaRepuestosBD(usuario: any){
    const payload = usuario.data.usuario_cliente;
    this.showLoading = true;

    this.getProductosBD$ = this.carritoSrv.getProductosBD(payload).subscribe((resp: any)=>{
      this.showLoading = false;
      this.listaRepuestos = resp.data;
      
    }, (error: HttpErrorResponse)=>{
      this.showLoading = false;
      console.log('error',error);
    });
  }

  async okTerminos(event: Event ){
    const element = event.target as HTMLInputElement;

    if(element.checked){
      this.msg_entrega = true;
      this.showLoading = true;  
      const msgCarrito = await this.validarDisponibilidad();
      this.showLoading = false;
      this.carritoSrv.setItem('carritoTmp', this.listaRepuestos);
      
      if(msgCarrito){
        this.utilSrv.showAlert(msgCarrito,'Advertencia!','warning');
      }else{
        this.generarReferencia();
      } 

    }else{
      this.carritoSrv.removeLocalStore('carritoTmp');
      this.terminos = false;
      this.deshabilitar = true;
      this.msg_entrega = false;
    }
  }

  
  async validarDisponibilidad(){
    let msg:any = ''; 

    if(this.listaRepuestos.length == 0){
      msg = 'No hay items en el carrito de compras';
    }
    
    for (let x in this.listaRepuestos){
      try {
        let resp:any =  await this.busquedaSrv.getDisponibilidad(this.listaRepuestos[x].id);
        let msgResp = this.compararCantidad( this.listaRepuestos[x],resp.datos);
        
        if(msg){
          msg = `${msg} ${msgResp}`;
        }else{
          msg = msgResp;
        } 

      } catch (error) {
        console.log('error',error);
        return 'Error al consultar la disponibilidad';
      }
    }

    return msg;
  }

  getIvaSinSeparadorMiles(){
    return this.getIVA().toFixed(2);
  } 


  compararCantidad(itemCarrito: any, itemBodega:any){
    let msg = '';
    if(itemCarrito.cantidad > parseInt(itemBodega.refebode_disponible) ){
      msg = `No hay suficiente cantidad en bodega del repuesto ${itemBodega.referencia_nombre} `;
    }
    return msg;
  }

  generarReferencia(){

    if(this.signature){
      this.deshabilitar = false;
      return;
    }

    this.referencePago = `PAGOTIENDA${Math.floor(Math.random() * (50000 - 1000 + 1)) + 1000}`;

    const payload = {
      apiKey      : this.apiKey,
      merchantId  : this.merchantId,
      reference   : this.referencePago,
      amount      : this.getTotalPrice(),
      currency    : this.currency
    }

    this.carritoSrv.getSignaturePayu(payload).subscribe({
      next: (resp: any)=>{
        if( resp.state ){
          this.signature = resp.data;
          this.deshabilitar = false;
        }
      },
      error: (error)=>{
        console.log('error',error);
      }
    });
  }


  getTotalPrice(): number {
    return this.listaRepuestos.reduce((sum: any, item: any) => sum + parseInt(item.precio) * item.cantidad, 0);
  }
  
  getIVA(): number {
    return this.getImporte()*this.iva;
  }
  
  getImporte(): number {
    return this.getTotalPrice()/this.ivaplus;
  }


}
