export class Busqueda{
    anosModelo?: string;
    familia?:any;
    vin?: string;
    placa?: string;
    valueFamilia?: any;
    caja?: any;
    valueCaja?: any;
    combustible?: any;
    valueCombustible?: any;
    lineas?: string;
    valueLinea?: string;
    selectedInfo?: any;
    selectedValue: any;
    selectedValueFamilia: any;
    selectedCajaValue!: any;
    selectedInfoCaja!: any;
    selectedCombustibleValue!: any;
    selectedInfoCombustible!: any;

}