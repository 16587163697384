import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthData } from 'src/app/interfaces/auth.interfece';
import { RetornoCiudades, RetornoDpto } from 'src/app/interfaces/depto.interface';
import { AuthService } from 'src/app/services/auth.services';
import { BusquedaService } from 'src/app/services/busqueda.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-form-cliente',
  templateUrl: './form-cliente.component.html',
  styleUrls: ['./form-cliente.component.css']
})

export class FormClienteComponent implements OnInit, OnDestroy {

  formCliente: FormGroup;
  @Input() campos?: AuthData;
  isloading = false;
  dptos?: RetornoDpto[] = [];
  ciudades?: RetornoCiudades[] = [];
  getClienteSiisa$    = new Subscription();
  crearClienteSiisa$  = new Subscription();

  constructor(private fb: FormBuilder, private utilService: UtilService, private busquedaSrv: BusquedaService, private router: Router, private authSrv: AuthService ){

  
    this.formCliente = this.fb.group({
      t_Identificacion: ['0', [Validators.required, Validators.pattern    (/^(?!0$).+/)]],
      cliente_identificacion: ['', [Validators.required]],
      cliente_tipopersona: ['0', [Validators.required, Validators.pattern(/^(?!0$).+/)]],
      prnombre: ['', [Validators.required]],
      sgdonombre: '',
      prapellido: ['', [Validators.required]],
      sgdoapellido: '',
      email: ['', [Validators.required, this.utilService.validarEmail]],
      cliente_departamento: ['0', [Validators.required, Validators.pattern(/^(?!0$).+/)]],
      cliente_ciudad: ['0', [Validators.required, Validators.pattern(/^(?!0$).+/)]],
      direccion: ['', [Validators.required, Validators.minLength(6)]],
      cliente_telefono:'',
      cliente_celular:['',[this.utilService.validaCelular]],
    });

  }

  ngOnInit(): void {
    this.formCliente.get('t_Identificacion')?.setValue(this.campos?.cliente.cliente_tipoidentificacion);
    this.formCliente.get('cliente_identificacion')?.setValue(this.campos?.cliente.cliente_identificacion);
    this.formCliente.get('cliente_tipopersona')?.setValue(this.campos?.cliente.cliente_tipopersona);
    this.formCliente.get('prnombre')?.setValue(this.campos?.cliente.cliente_nombre_1);
    this.formCliente.get('sgdonombre')?.setValue(this.campos?.cliente.cliente_nombre_2);
    this.formCliente.get('prapellido')?.setValue(this.campos?.cliente.cliente_apellido_1);
    this.formCliente.get('sgdoapellido')?.setValue(this.campos?.cliente.cliente_apellido_2);
    this.formCliente.get('email')?.setValue(this.campos?.usuario_email);
    this.formCliente.get('cliente_departamento')?.setValue(this.campos?.cliente.cliente_departamento);
    this.formCliente.get('cliente_ciudad')?.setValue(this.campos?.cliente.cliente_ciudad);
    this.formCliente.get('direccion')?.setValue(this.campos?.cliente.cliente_direccion);
    this.formCliente.get('cliente_telefono')?.setValue(this.campos?.cliente.cliente_telefono);
    this.formCliente.get('cliente_celular')?.setValue(this.campos?.cliente.cliente_celular);
 
    this.inHabilitarCampos();

    this.cargarDptos();

  }


  onSubmit(){
    console.log('onSubmit');
  }

  validaCadena(e: any ){

  }

  inHabilitarCampos(){
    console.log('ngoninit', this.formCliente.get('t_Identificacion'))
    this.formCliente.get('t_Identificacion')!.disable();
    this.formCliente.get('cliente_identificacion')!.disable();
    this.formCliente.get('cliente_tipopersona')!.disable();
    this.formCliente.get('prnombre')!.disable();
    this.formCliente.get('sgdonombre')!.disable();
    this.formCliente.get('prapellido')!.disable();
    this.formCliente.get('sgdoapellido')!.disable();
    this.formCliente.get('email')!.disable();

  }


  cargarDptos(){
    this.busquedaSrv.getDeptos().subscribe({
      next: (data)=>{
        this.dptos = data.datos.retorno;
      },
      error: (error)=>{
        console.log('error',error);
      },
      complete: ()=>{
        const dpto = this.formCliente.get('cliente_departamento')?.value;
        this.cargarCiudadesDpto(null , dpto);
      }
    });
  }

  cargarCiudadesDpto(event: Event | null, dpto: string = ''){

    let valor = null;

    if (event != null){
      let selectElement = (event.target) as HTMLSelectElement;
      valor = selectElement.value;
    }else if(dpto){
      valor = dpto;
    }else{
      return;
    }
    
    if(!valor){
      return;
    } 

    this.ciudades = [];

    this.busquedaSrv.getCiudadesXdpto(valor).subscribe({
      
      next: (data)=>{
        this.ciudades = data.datos.retorno;
      },

      error: (error)=>{
        console.log('error',error);
      }
    });
  }

  
  confirmar(){
   
    const logIn = this.authSrv.isLoggedIn();

    if(logIn){
      this.validarCliente();
    }else{
      this.router.navigate(['/home']);
    }
   
  }

  validarCliente(){
    
    let userLS      = this.authSrv.getUsuario();
    this.isloading  = true;

    const payload = {
      nit: this.campos!.cliente.cliente_identificacion,
      tipodocumento: this.campos!.cliente.cliente_tipoidentificacion
    }

    if( userLS.data.cliente.clierepu_codigo ){
      this.isloading = false;
      this.router.navigate(['/confirmar']);
      return;
    }

    this.getClienteSiisa$ =this.authSrv.getClienteSiisa(payload).subscribe({
      next: (resp)=>{
        
        this.isloading = false;
        
        if(resp.success == 'true'){
          
          if(resp.datos[0].clierepu_codigo){
            this.routeConfirmarPage(userLS, resp);
          }else{
            this.crearClienteSiisa(userLS);
          }
        }

      },

      error:(error: HttpErrorResponse)=>{
        console.log('error',error);
        this.isloading = false;
        this.utilService.showAlert('Se presentaron problemas al recuperar el cliente', 'Error!', 'error');
      }
    });
  }

  
  
  crearClienteSiisa(userLS: any){

    this.isloading = true;

    const payload = {
      tipocliente:         12,
      tipoidentificacion:  this.campos?.cliente.cliente_tipoidentificacion,
      nit:                 this.campos?.cliente.cliente_identificacion,
      tipopersona:         this.campos?.cliente.cliente_tipopersona,
      apellido1:           this.campos?.cliente.cliente_apellido_1,
      apellido2:           this.campos?.cliente.cliente_apellido_2,
      nombre1:             this.campos?.cliente.cliente_nombre_1,
      nombre2:             this.campos?.cliente.cliente_nombre_2,
      departamento:        this.campos?.cliente.cliente_departamento,
      ciudad:              this.campos?.cliente.cliente_ciudad,
      direccion:           this.campos?.cliente.cliente_direccion,
      telefono:            this.campos?.cliente.cliente_telefono,
      celular:             this.campos?.cliente.cliente_celular,
      email:               this.campos?.usuario_email 
    }

    this.crearClienteSiisa$ = this.authSrv.crearClienteSiisa(payload).subscribe({
      
      next: (resp)=>{

        this.isloading = false;
        
        if(resp.datos[0].estadoproceso == "ROLLBACK"){
          console.log('resp',resp);
          this.utilService.showAlert('No se pudo crear el cliente en SIISA', 'Advertencia!','warning');
        }else{
          resp.datos[0].clierepu_codigo = resp.datos[0].mensaje[0];
          this.routeConfirmarPage(userLS, resp);
        }

      },
      error: (error: HttpErrorResponse)=>{
        console.log('error',error);
        this.isloading = false;
        this.utilService.showAlert('Se presentaron problemas al crear el cliente', 'Error!', 'error');
      }
    });


  }

  /********** GUARDAMOS EL CODIGO DEL UUARIO CREADO EN LOCALSTORAGE **************/
  routeConfirmarPage( userLS: any, resp: any){
    if( userLS ){
      userLS.data.cliente.clierepu_codigo = resp.datos[0].clierepu_codigo;
      this.authSrv.setAuth(userLS);
      this.router.navigate(['/confirmar']);
    }
  }


  ngOnDestroy(): void {
    if( this.crearClienteSiisa$ ){
      this.crearClienteSiisa$.unsubscribe();
    }

    if( this.getClienteSiisa$ ){
      this.getClienteSiisa$.unsubscribe()
    }
  }
 
}