import { Component, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { catchError, of, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { BusquedaService } from 'src/app/services/busqueda.service';
import { Producto } from 'src/app/models/producto.models';
import { UtilService } from 'src/app/services/util.service';
import { CarrocompraService } from 'src/app/services/carrocompra.service';
import { AuthService } from 'src/app/services/auth.services';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-repuesto',
  templateUrl: './repuesto.component.html',
  styleUrls:['./repuesto.component.css']

})

export class RepuestoComponent implements OnDestroy{

  showLoading=false;
  producto: Producto = new Producto();
  productosnew: any[] = [];
  disponible = 0;
  url: any;
  inputBusqueda?: any;
  codigo: any;
  numeroLinea: any;
  inputCantidad: number = 0;
  vin = '';
  cantDisponible = 0;
  bandbuscarRefEnCarrito = 0;
  vlrCompraMinima = environment.VALOR_COMPRA_MINIMA;
  envioGratis = environment.ENVIO_GRATIS;
  

  //subscripciones
  private getCarritoDb$: Subscription = new Subscription();
  private obtenerReferencia$: Subscription = new Subscription();
  private addCarritoDB$: Subscription = new Subscription();
  private updateItemCarritoBD$: Subscription = new Subscription();

  
  constructor( private busquedaService: BusquedaService, private router: ActivatedRoute, private utilService: UtilService, 
               private carrodecompraService: CarrocompraService, private authService: AuthService) {
    this.url = 'https://siisarepuestos.s3-us-west-2.amazonaws.com/TV/';

    this.router.params.subscribe(params => {

      this.inputBusqueda = (params['inputbusqueda']);
      console.log('params', params);

    });


  }

  getVin = this.busquedaService.vin$;

  ngOnInit(): void {
    console.log('ngOnInit-repuesto')
    this.getProductosCarrito();
    setTimeout(() => {
      this.obtenerReferencia();
    }, 500);
  }


  obtenerReferencia() {

    this.obtenerReferencia$ =this.busquedaService.getReferencia(this.inputBusqueda)
      .pipe(
        catchError(error => {
          console.error('Error al obtener los datos:', error);
          return of([]);
        })
      )
      .subscribe((response: any) => {

        if (response && response.success === 'true') {


          this.producto.nombre = response.datos.referencia_nombre;
          if (response.datos.referencia_imagen.length === 0) {
            this.producto.imagen = ' /assets/img/marcaaguanewsize.webp'
          } else {
            this.producto.imagen = this.url + response.datos.referencia_imagen;
          }

          this.producto.precio = response.datos.referencia_preciovigente;
          this.producto.referencia_codigo = response.datos.referencia_codigo;
          this.producto.stock = response.datos.refebode_disponible;
          
          if(this.producto.stock){
            this.cantDisponible = this.producto.stock;
            if(this.cantDisponible > 0){
              this.disponible = 1;
            }else{
              this.disponible = 0;
            }
          }
          
          this.buscarRefEnCarrito();


        }
        else {
          console.error('La solicitud a la API no fue exitosa');

        }


      });

  }

  buscarRefEnCarrito(){
    const ref = this.inputBusqueda;
    this.bandbuscarRefEnCarrito = 1;
    
    if( this.productosnew.length > 0){
      this.productosnew.filter((item: any)=>{
        if(item.id == ref){
          const cantidad = this.cantDisponible - parseInt(item.cantidad);
          this.cantDisponible = cantidad;
          if(this.cantDisponible > 0){
            this.disponible = 1;
          }else{
            this.disponible = 0;
          }
        }
      });
    }
  }


  aumentarCantidad() {
    this.inputCantidad += 1;
    this.cantDisponible-=1;
    if( this.inputCantidad > 0 ){
      if(this.cantDisponible >= 0){
        this.disponible = 1;
      }else{
        this.disponible = 0;
      }
    }
  }

  disminuirCantidad() {
    
    if(this.inputCantidad == 0){
      return;
    }

    this.cantDisponible += 1;
    this.inputCantidad += -1;

    if( this.inputCantidad > 0 ){
      if( this.cantDisponible >= 0 ){
        this.disponible = 1;
      } else{
        this.disponible = 0;
      } 
    }
  }


  anadirCarrito(producto: any) {

    if (this.inputCantidad < 1) {
      this.utilService.showAlert('Debes agregar cantidad', 'Advertencia!', 'warning');
      return;
    }

    if( this.disponible == 0 ){
      return;
    }

    const nuevoProducto = {
      id: producto.referencia_codigo,
      cantidad: this.inputCantidad,
      caracteristicas: '',
      codigo: producto.referencia_codigo,
      descripcion: '',
      imagen: producto.imagen,
      iva: '',
      marca: '',
      nombre: producto.nombre,
      precio: producto.precio,
      preciosiniva: ''
    };

    if( this.authService.isLoggedIn() ){
      this.buscarItemCarritoDB(nuevoProducto);
    }else{
      this.addCarritoLocal(nuevoProducto);
    }

  }




  addCarritoLocal(payload: any){
    this.carrodecompraService.AñadirCarroCompra(payload);
    this.utilService.showAlert('Has añadido un respuesto a tu carro de compras', 'Exito!', 'success');
    const carritoLS = this.carrodecompraService.getItem('carrito');
    if(carritoLS){
      this.productosnew = carritoLS;
    }
    this.inputCantidad = 0;
    this.obtenerReferencia();
  }

    
  getProductosCarrito(){
    if( this.authService.isLoggedIn() ){
      this.getCarritoDb();
    }else{
      this.getCarritoLocal();
    }
  }

  /********** OBTENER LOS PRODUCTOS DEL CARRITO BASE DE DATOS ****************** */
  getCarritoDb(){
    const usuario = this.authService.getUsuario();

    if (usuario == null) {
      return;
    }

    const payload = usuario.data.usuario_cliente;
    this.getCarritoDb$ = this.carrodecompraService.getProductosBD(payload).subscribe((resp: any) => {
      this.ocultarLoading();
      
      if( resp.state){
        this.carrodecompraService.updateCantidadItemsCarrito(resp.data);
        this.productosnew = resp.data;
        
        if(this.bandbuscarRefEnCarrito){
          this.obtenerReferencia();
        }
      }

    }, (error: HttpErrorResponse) => {
      this.ocultarLoading();
      console.log('error', error);
      let msg = '';
      if (typeof (error.error) != 'object') {
        msg = error.message;
        this.utilService.showAlert(msg, 'Error!', 'error');
      }
    });
  }

  getCarritoLocal(){
    this.ocultarLoading();
    const carritoLS = this.carrodecompraService.getItem('carrito');
    const ref = this.inputBusqueda;
    
    if(carritoLS){
      this.productosnew = carritoLS;
      this.buscarRefEnCarrito();
    }

  }

  /************ BUSCAMOS EL REPUESTO SI YA ESTA ADICIONADO EN EL CARRITO DE BASE DE DATOS ******************* */
  buscarItemCarritoDB(producto: any) {
 
    let cantidad = 0;
    let item: any = this.productosnew.filter((resp: any) => {
      if (resp.id == producto.id) {
        cantidad = resp.cantidad + producto.cantidad;
        return resp;
      }
    });

    //si existe actualizamos la cantidad
    if (item.length > 0) {
      producto = { ...producto, cantidad };
      this.updateCarritoDb(producto);
   } else {
      this.addCarritoDb(producto);
    }
  }

  /************* ADICIONAR ITEMS AL CARRITO BASE DE DATOS ******************* */
  addCarritoDb(producto: any) {
    const usuario = this.authService.getUsuario();

    if (usuario == null) {
      this.utilService.showAlert('No hay un usuario válido en sesión.', 'Advertencia!', 'warning');
      return;
    }
    this.mostrarLoading();

    const payload = [{
      carcomp_cliente: usuario.data.usuario_cliente,
      carcomp_producto: producto.codigo,
      carcomp_cantidad: producto.cantidad,
      carcomp_precio: producto.precio,
      carcomp_prodnomb: producto.nombre,
      carcomp_imagen: producto.imagen,
      //carcomp_tamanno: 0,
      carcomp_proddescrip: producto.descripcion,
      carcomp_marca: producto.marca,
      carcomp_caracteristicas: producto.caracteristicas,
      carcomp_presiniva: producto.precio,
      //carcomp_presiniva: producto.preciosiniva,
      carcomp_iva: 0,
      carcomp_restriccion: '',
      carcomp_unidadminima: 1,
      carcomp_estado: 'AC'
    }];

    this.addCarritoDB$ = this.carrodecompraService.addCarritoDB(payload).subscribe((resp: any) => {
      this.ocultarLoading();

      if (resp.state) {
        this.utilService.showAlert('Has añadido un respuesto a tu carro de compras', 'Exito!', 'success');
        this.productosnew = [...this.productosnew, producto];
        this.carrodecompraService.updateCantidadItemsCarrito(this.productosnew );
        
        if(this.cantDisponible > 0){
          this.disponible = 1;
        }else{
          this.disponible = 0;
        }
        this.inputCantidad = 0;
        this.obtenerReferencia();
      }
    }, (error: HttpErrorResponse) => {
      console.log('error', error);
      this.ocultarLoading();
      if (typeof (error.error) == 'object') {
        this.utilService.showAlert(error.error.message, 'Error!', 'error');
      } else {
        this.utilService.showAlert(error.message, 'Error!', 'error');
      }
    });
  }


  /***************** ACTUALIZAMOS ITEM CARRITO BASE DE DATOS ************************************* */
  updateCarritoDb(producto: any){
    const usuario = this.authService.getUsuario();

    if (usuario == null) {
      this.utilService.showAlert('No hay un usuario válido en sesión.', 'Advertencia!', 'warning');
      return;
    }
    
    const item = {
      carcomp_cliente: usuario.data.usuario_cliente,
      carcomp_producto: producto.codigo,
      carcomp_cantidad: producto.cantidad,
      carcomp_precio: producto.precio,
      carcomp_prodnomb: producto.nombre,
      carcomp_imagen: producto.imagen,
      //carcomp_tamanno: 0,
      carcomp_proddescrip: producto.descripcion,
      carcomp_marca: producto.marca,
      carcomp_caracteristicas: producto.caracteristicas,
      carcomp_presiniva: producto.precio,
      carcomp_iva: 0,
      carcomp_restriccion: '',
      carcomp_unidadminima: 1,
      carcomp_estado: 'AC'
    };
    
    const payload = {
      id: producto.id,
      cliente: usuario.data.usuario_cliente,
      producto: item
    }
    
    this.mostrarLoading();
    this.updateItemCarritoBD$ =this.carrodecompraService.updateItemCarritoBD(payload).subscribe((resp: any)=>{
      this.ocultarLoading();

      if(resp.state){
          this.productosnew = this.productosnew.map((resp: any)=>{
          if(resp.id == producto.id){
            return {...resp, cantidad: producto.cantidad};
          }
          return resp;
        });
      
        this.carrodecompraService.updateCantidadItemsCarrito(this.productosnew);
        this.inputCantidad = 0;
        this.obtenerReferencia();
      }
    }, (error: HttpErrorResponse)=>{
      this.ocultarLoading();
      console.log('error',error);
      if(typeof(error.error) != 'object' ){
        this.utilService.showAlert(error.message,'Error!','error');
      }else{
        this.utilService.showAlert(error.error.message,'Error!','error');
      }

    });
  }


  // funcion para activar y desactivar el loading
  mostrarLoading() {
    this.showLoading = true;
  }

  ocultarLoading() {
    this.showLoading = false;
  }

  get getDisponible(){
    return this.disponible==1?'SI':'NO';
  }

  ngOnDestroy(): void {
    if (this.getCarritoDb$) {
      this.getCarritoDb$.unsubscribe();
    }
    if (this.obtenerReferencia$) {
      this.obtenerReferencia$.unsubscribe();
    }
    if (this.addCarritoDB$) {
      this.addCarritoDB$.unsubscribe();
    }
    if (this.updateItemCarritoBD$) {
      this.updateItemCarritoBD$.unsubscribe();
    }
  }

}
