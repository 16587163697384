import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.services';
import { CarrocompraService } from 'src/app/services/carrocompra.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-confirmacion-pago',
  templateUrl: './confirmacion-pago.component.html',
  styleUrls: ['./confirmacion-pago.component.css']
})
export class ConfirmacionPagoComponent implements OnInit, OnDestroy {

  msg='';
  usuario:any;
  carrito:any = [];
  carritoDetalle = [];
  updateEstadoCarrito$  = new Subscription();
  crearPedidoSiisa$     = new Subscription();

  constructor(private activatedRoute: ActivatedRoute, private carrocompraSrv:CarrocompraService, private authSrv: AuthService, private utilSrv: UtilService){ }
   
  ngOnInit(): void {
    this.getItemsCarrito();
    this.getDatosUsuario();
    this.confirmacion();
  }


  confirmacion(){
    this.activatedRoute.queryParams.subscribe((param:any)=>{
      //TODO:VALIDAR ESTADO PENDING EN LA PASARELA
      if(param.lapTransactionState === 'APPROVED'){
        this.msg = 'Su pedido fue realizado con Exito';
        this.updateEstadoCarrito(param);
      }else{
        this.msg = 'Se presentaron problemas al realizar el pedido';
      }
    });
  }

  
  /************ ACTUALIZAMOS EL ESTADO DEL CARRITO EN EL BACKEND ******************/
  updateEstadoCarrito(respPayu: any){

    const payload = {
      id_cliente: this.usuario.data.cliente.cliente_codigo, 
      carcomp_estado: 'IN', 
      carcomp_estado_transaccion: respPayu.lapTransactionState,
      carcomp_refrerencia_pago_pasarela: respPayu.reference_pol,
      carcomp_obj_transaccion: JSON.stringify(respPayu)
    } 

    this.updateEstadoCarrito$ = this.carrocompraSrv.updateEstadoCarrito(payload).subscribe({
      next: (resp:any)=>{
        console.log('resp',resp);
        if(resp.state){
          this.carrocompraSrv.incrementCantidad(0);
          this.crearPedidoSiisa();
        }else{
          this.utilSrv.showAlert('No se pudo al actualizar pedido', 'Advertencia!','warning');
        }
      },
      error: (error)=>{
        console.log('error',error);
        this.utilSrv.showAlert('Se presentaron problemas al actualizar pedido', 'Error!','error');
      }
    });
  }


  /******* OBTENEMOS LOS PRODUCTOS DEL CARRITO ***************/
  getItemsCarrito(){
    this.carrito = this.carrocompraSrv.getItem('carritoTmp');
    this.carritoDetalle = this.detallePago();
  } 


 /************ CREAMOS EL PEDIDO EN SIISA ******************/
  crearPedidoSiisa(){

    const payload = {
      encabezado:[
        {
          empresa: "SSY",
          ClienteCodigo: this.usuario.data.cliente.clierepu_codigo,
          numeropedido: 0,
          bodega: "02",
          tipopedido:"01",
          fpedido: new Date(),
          destino:"00"
        }
      ],
      observaciones: "",
      detalle: this.carritoDetalle
    }

    this.crearPedidoSiisa$ =this.carrocompraSrv.crearPedidoSiisa(payload).subscribe({
      next: (resp)=>{
    
        if(resp.datos[0].estadoproceso == "ROLLBACK"){
          this.utilSrv.showAlert('No se pudo crear el pedido', 'Advertencia!', 'warning');
        }else{
          this.utilSrv.showAlert('Su pedido fue exitoso', 'Exito!', 'success');
          //TODO:PENDIENTE API LOG PASARELA SIISA
        }

      },
      error:(error:HttpErrorResponse)=>{
        console.log('error creando pedido en siisa',error);
      }
    });
  }

   /************ OBTENEMOS LOS DATOS DEL USUARIO *************/
  getDatosUsuario(){
    this.usuario = this.authSrv.getUsuario();
  }

  detallePago(){
    if(this.carrito){
      return this.carrito.map((i:any)=>{
        return {
          referencia: i.id,
          cantidad: i.cantidad,
          precio: parseInt(i.precio),
          flete: 1000
        }
      });    
    }
  }

  
  /************ DESTRUIMOS LAS SUBSCRIPCIONES ****************/
  ngOnDestroy(): void {
    if( this.updateEstadoCarrito$ ){
      this.updateEstadoCarrito$.unsubscribe(); 
    }
    if( this.crearPedidoSiisa$ ){
      this.crearPedidoSiisa$.unsubscribe(); 
    }
  }

  
}
