import { HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.services';
import { CarrocompraService } from 'src/app/services/carrocompra.service';
import { UtilService } from 'src/app/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  deleteItemCarritoDB$: Subscription = new Subscription(); 
  getProductosBD$: Subscription = new Subscription(); 
  updateItemCarritoBD$: Subscription = new Subscription(); 
  carritoItems$: Subscription = new Subscription(); 
  frase = environment.FRASE_TIENDA;
  compra_minima = environment.VALOR_COMPRA_MINIMA;
  rangeValue = 0;
  

  listaRepuestos:any = [];
  showLoading = false;

  iva = 0.19;
  ivaplus = 1.19;

  constructor(private carritoService: CarrocompraService, private authService: AuthService, private utilService: UtilService, private router: Router, private location: Location){ }
  
  ngOnInit(): void {
    this.listarRepuestos();
    this.carritoItems$ = this.carritoService.itemsCarrito$.subscribe((items)=>{
      this.listaRepuestos = items;  
    });
  }


  getTotalQuantity(): number {
    return this.listaRepuestos.reduce((sum: any, item: any) => sum + item.cantidad, 0);
  }

  getTotalPrice(): number {
    return this.listaRepuestos.reduce((sum: any, item: any) => sum + parseInt(item.precio) * item.cantidad, 0);
  }
  
  getIVA(): number {
    return this.getImporte()*this.iva;
  }
  
  getImporte(): number {
    return this.getTotalPrice()/this.ivaplus;
  }

  eliminarItem(id: string){
    if(this.authService.isLoggedIn()){
      this.eliminarItemBd(id);
    }else{
      this.eliminarItemLocal(id);
    }
  }

  eliminarItemLocal(id: string){
    const datos = this.carritoService.removeItem(id);
    this.listaRepuestos = this.carritoService.getRepuestos();
  }
  
  eliminarItemBd(id: string){
    const usuario = this.authService.getUsuario();

    if(usuario == null){
      this.utilService.showAlert('No se puede eliminar item, no hay usuario en la sesión.');
      return;
    }
    const payload = {
        cliente: usuario.data.usuario_cliente,
        id_producto: id 
      };

     this.showLoading = true;

      this.deleteItemCarritoDB$ = this.carritoService.deleteItemCarritoDB(payload).subscribe((resp:any)=>{
        console.log('resp',resp);
        this.showLoading = false;
        if(resp.state){
          let cantidad = 0;
          this.listaRepuestos = this.listaRepuestos.filter((resp: any)=>{
            if( resp.id != id ){
              console.log('resp-delete',resp);
              cantidad = cantidad + resp.cantidad; 
              return  resp;
            }
          });
          
          this.carritoService.incrementCantidad(cantidad);
        }
      }, (error: HttpErrorResponse)=>{
        console.log('error',error);
        this.showLoading = false;
        const msg = error.error.message?error.error.message:error.message;
        this.utilService.showAlert(msg, 'Error!','error');
      });
  }

  listarRepuestos(){
    const usuario = this.authService.getUsuario();

    if(usuario == null){
      this.listaRepuestosLocalStorage();
    }else{
      this.listaRepuestosBD(usuario);
    }
  }


  listaRepuestosLocalStorage(){
    this.carritoService.setItemsCarritoBD(this.carritoService.getRepuestos())
  }

  listaRepuestosBD(usuario: any){
    const payload = usuario.data.usuario_cliente;
    this.showLoading = true;

    this.getProductosBD$ = this.carritoService.getProductosBD(payload).subscribe((resp: any)=>{
      this.showLoading = false;
      this.listaRepuestos = resp.data;
    }, (error: HttpErrorResponse)=>{
      this.showLoading = false;
      console.log('error',error);
    });
  }

  
  removeItem(item: any){
    if(item.cantidad == 1){
      return;
    }

    if(this.authService.isLoggedIn()){
      const cantidadActual = item.cantidad;
      item.cantidad  = item.cantidad - 1;
      this.updateCarritoDb(item, cantidadActual);
    }else{
      const itemLocalStorage = this.carritoService.getItem('carrito');
      if(itemLocalStorage.cantidad == 1){
        return;
      }

      this.listaRepuestos = itemLocalStorage.map((resp: any)=>{
        if(resp.id == item.id){
          return {...resp, cantidad: resp.cantidad - 1};
        }
        return resp;
      });
      this.carritoService.updateCantidadItemsCarrito(this.listaRepuestos);
      this.carritoService.setItem('carrito', this.listaRepuestos);
    }
  }


  addItem(item: any){
    if(this.authService.isLoggedIn()){
      const cantidadActual = item.cantidad;
      item.cantidad  = item.cantidad + 1;
      this.updateCarritoDb(item, cantidadActual);

    }else{

      const itemLocalStorage = this.carritoService.getItem('carrito');
      this.listaRepuestos = itemLocalStorage.map((resp: any)=>{
        if(resp.id == item.id){
          return {...resp, cantidad: resp.cantidad + 1};
        }
        return resp;
      });
      this.carritoService.updateCantidadItemsCarrito(this.listaRepuestos);
      this.carritoService.setItem('carrito', this.listaRepuestos);
    }
  }

  
 
  /***************** ACTUALIZAMOS ITEM CARRITO BASE DE DATOS ************************************* */
  updateCarritoDb(producto: any, cantActual: number){
    const usuario = this.authService.getUsuario();

    if (usuario == null) {
      this.utilService.showAlert('No hay un usuario válido en sesión.', 'Advertencia!', 'warning');
      return;
    }
    
    const item = {
      carcomp_cliente: usuario.data.usuario_cliente,
      carcomp_producto: producto.codigo,
      carcomp_cantidad: producto.cantidad,
      carcomp_precio: producto.precio,
      carcomp_prodnomb: producto.nombre,
      carcomp_imagen: producto.imagen,
      //carcomp_tamanno: 0,
      carcomp_proddescrip: producto.descripcion,
      carcomp_marca: producto.marca,
      carcomp_caracteristicas: producto.caracteristicas,
      carcomp_presiniva: producto.preciosiniva,
      carcomp_iva: 0,
      carcomp_restriccion: '',
      carcomp_unidadminima: 1,
      carcomp_estado: 'AC'
    };
    
    const payload = {
      id: producto.id,
      cliente: usuario.data.usuario_cliente,
      producto: item
    }
    
    this.showLoading = true;
    this.updateItemCarritoBD$ = this.carritoService.updateItemCarritoBD(payload).subscribe((resp: any)=>{
      this.showLoading = false;

      if(resp.state){
        this.listaRepuestos = this.listaRepuestos.map((resp: any)=>{
          if(resp.id == producto.id){
            return {...resp, cantidad: producto.cantidad};
          }
          return resp;
        });
        this.carritoService.updateCantidadItemsCarrito(this.listaRepuestos);

    
      }else{

        this.listaRepuestos = this.listaRepuestos.map((resp: any)=>{
          if(resp.id == producto.id){
            return {...resp, cantidad: cantActual};
          }
         return resp;
        });

      }

    }, (error: HttpErrorResponse)=>{
      this.showLoading = false;
      console.log('error',error);
      
      this.listaRepuestos = this.listaRepuestos.map((resp: any)=>{
        if(resp.id == producto.id){
          return {...resp, cantidad: cantActual};
        }
        return resp;
      });

      if(typeof(error.error) != 'object' ){
        this.utilService.showAlert(error.message,'Error!','error');
      }else{
        this.utilService.showAlert(error.error.message,'Error!','error');
      }
    });
  }


  /********** CERRAR SUBSCRIPCIONES ********* */
  ngOnDestroy(): void {
    if (this.deleteItemCarritoDB$) {
      this.deleteItemCarritoDB$.unsubscribe();
    }
    if (this.getProductosBD$) {
      this.getProductosBD$.unsubscribe();
    }
    if (this.updateItemCarritoBD$) {
      this.updateItemCarritoBD$.unsubscribe();
    }
    if (this.carritoItems$) {
      this.carritoItems$.unsubscribe();
    }
  }

  comprar(){
    const valorTot = this.getTotalPrice();

    if( valorTot < this.compra_minima ){
      const msg = `La compra mínima debe ser de $ ${this.compra_minima.toLocaleString()}`;
      this.utilService.showAlert(msg, 'Advertencia!','warning');
      return;
    }

    this.router.navigate(["/datosdeenvio"]);
  }

  atras(){
    this.location.back();
  }

}
