import { Component } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
import { RepuestoComponent } from "./components/repuesto/repuesto.component";
import { RepuestosComponent } from "./components/repuestos/repuestos.component";
import { CompraComponent } from "./components/compra/compra.component";
import { CarrodecompraComponent } from "./carrodecompra/carrodecompra.component";
import { CheckoutComponent } from "./components/checkout/checkout.component";
import { ResetPasswordComponent } from "./components/pages/auth/reset-password/reset-password.component";
import { DatosdeenvioComponent } from "./datosdeenvio/datosdeenvio.component";
import { ConfirmarComponent } from "./components/confirmar/confirmar.component";
import { ConfirmacionPagoComponent } from "./components/pages/pago/confirmacion-pago/confirmacion-pago.component";


const APP_ROUTES: Routes =[

     {path: 'home', component: HomeComponent },
     {path: 'repuestos/:inputbusqueda', component: RepuestosComponent },
     {path: 'compra', component: CompraComponent },
     {path: 'carrodecompra', component: CarrodecompraComponent },
     {path: 'repuesto/:inputbusqueda', component: RepuestoComponent },
     {path: 'checkout', component: CheckoutComponent },
     {path: 'datosdeenvio', component: DatosdeenvioComponent},
     {path: 'confirmar', component: ConfirmarComponent},
     {path: 'auth/reset-password/:token', component: ResetPasswordComponent },
     {path: 'confirmar-pago', component: ConfirmacionPagoComponent },

     {path: '**', pathMatch: 'full', redirectTo: 'home' }

];

     export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES);



