<div>
    <form method="post" action="https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/" ngNoForm>
        <input name="merchantId"      type="hidden"  value="{{merchantId}}"   >
        <input name="accountId"       type="hidden"  value="{{accountId}}" >
        <input name="description"     type="hidden"  value="Compra repuestos SsangYong / KGM"  >
        <input name="referenceCode"   type="hidden"    value="{{referenceCode}}" >
        <input name="amount"          type="hidden"  value="{{amount}}"   >
        <input name="tax"             type="hidden"  value="{{tax}}">
        <input name="taxReturnBase"   type="hidden"  value="{{taxReturnBase}}" >
        <input name="currency"        type="hidden"  value="{{currency}}" >
        <input name="signature"       type="hidden"    value="{{signature}}"  >
        <input name="test"            type="hidden"  value="{{test}}" >
        <input name="buyerEmail"      type="hidden"  value="{{buyerEmail}}" >
        <input name="responseUrl"     type="hidden"  value="{{responseUrl}}" >
        <input name="confirmationUrl" type="hidden"  value="{{confirmationUrl}}" >
        <input name="Submit"          type="submit"  value="Pagar" class="btn btn-primary btn-regreso rounded" [disabled]="habilitar">  
    </form>
</div>