<table class="table table-hover">
    <thead>
        <tr>
            <th *ngIf="producto">Producto</th>
            <th>Nombre</th>
            <th class="text-center">Cantidad</th>
            <th>Precio</th>
            <th>Total</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of data" class="tr-hover">
            <td *ngIf="producto">
                <img src="{{item.imagen}}" width="100px" height="100px" alt="Descripción de la imagen" class="img-fluid img-hover">
            </td>
            <td>{{ item.nombre }}</td>
            <td class="text-center tb-cantidad" noWrap="nowrap">
                <button *ngIf="btnAddDel" class="button-hover" (click)="removeItem(item)"><i class="bi bi-dash"></i></button>
                {{ item.cantidad }}
                <button *ngIf="btnAddDel" class="button-hover" (click)="addItem(item)"><i class="bi bi-plus"></i></button>
            </td>
            <td>{{ item.precio | currency:'USD':'symbol':'1.0-0'  }}</td>
            <td>{{ item.precio * item.cantidad | currency:'USD':'symbol':'1.0-0' }}</td> 
            <td *ngIf="btnEliminar">
                <button class="btn btn-danger btn-sm" (click)="eliminarItem(item.id)" ><i class="bi bi-trash"></i></button>
            </td> 
        </tr>
    </tbody>
</table>
