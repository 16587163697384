import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LineaService } from '../services/linea.service';
import { BusquedaService } from 'src/app/services/busqueda.service';
import { Busqueda } from 'src/app/models/busqueda.models';


import { catchError } from 'rxjs/operators';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { of } from 'rxjs';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  busqueda: Busqueda= new Busqueda();
  
 
  verLinea = false;
  lineas: any[] = [];
  valueLinea: any[] = []; 
  inputBusqueda!: string;
  jsonData!: string;
  selectedValue: any;
  nombreLinea:any;
  numeroLinea:any;
  formulario: any;
  form: FormGroup = this.fb.group({
    busqueda: '',
  });

  coordinadora = environment.URL_COORDINADORA;
  whatsapp = environment.URL_WHATSAPP;


  constructor(

    private lineaService: LineaService,
    private busquedaService: BusquedaService,
    private router: Router,
    private fb: FormBuilder
  ) { }
  
  
  ngOnInit(): void {
    this.obtenerAnosModelo();
    this.mostrarLinea();
  }
 
  
  obtenerAnosModelo() {
    this.lineaService
      .getAnosModelo()
      .pipe(
        catchError((error) => {
          console.error('Error al obtener los datos:', error);
          return of([]); // Devuelve un observable vacío en caso de error
        })
      )
      .subscribe((response: any) => {
        if (response && response.success === 'true') {
          this.busqueda.anosModelo = response.datos.retorno.map(
            (elemento: { vehiculo_annomodelo: any }) =>
              elemento.vehiculo_annomodelo
          );
        } else {
          console.error('La solicitud a la API no fue exitosa');
        }
      });
  }

  obtenerFamilia() {
    this.lineaService
      .getFamilia()
      .pipe(
        catchError((error) => {
          console.error('Error al obtener los datos:', error);
          return of([]); 
        })
      )
      .subscribe((response: any) => {
        if (response && response.success === 'true') {
          this.busqueda.familia = response.datos.retorno.map(
            (elemento: { tbnombline_descripcion: any }) =>
              elemento.tbnombline_descripcion
          );
          this.busqueda.valueFamilia = response.datos.retorno.map(
            (elemento: { tbnombline_codigo: any }) => elemento.tbnombline_codigo
          );

         } else {
          console.error('La solicitud a la API no fue exitosa');
        }
      });
  }
  obtenerIdFamilia() {
    this.busqueda.selectedValue = (<HTMLSelectElement>(
      document.getElementById('familia')
    )).value;
    this.busqueda.selectedInfo =
      this.busqueda.valueFamilia[this.busqueda.familia.indexOf(this.busqueda.selectedValue)];
    this.lineaService
      .getcajaCambios(this.busqueda.selectedInfo)
      .pipe(
        catchError((error) => {
          console.error('Error al obtener los datos:', error);
          return of([]);
        })
      )
      .subscribe((response: any) => {
        console.log(response);

        if (response && response.success === 'true') {
          this.busqueda.caja = response.datos.retorno.map(
            (elemento: { tbtransmision_descripcion: any }) =>
              elemento.tbtransmision_descripcion
          );
          this.busqueda.valueCaja = response.datos.retorno.map(
            (elemento: { tbtransmision_codigo: any }) =>
              elemento.tbtransmision_codigo
          );
        } else {
          console.error('La solicitud a la API no fue exitosa');
        }
      });
  }

  obtenerCombustible() {
    this.busqueda.selectedCombustibleValue = (<HTMLSelectElement>(document.getElementById('caja'))).value;
    this.busqueda.selectedInfoCombustible = this.busqueda.valueCaja[this.busqueda.caja.indexOf(this.busqueda.selectedCombustibleValue)];
    console.log(this.busqueda.selectedInfoCombustible);
    this.lineaService.getCombustible({
      selectedInfo: this.busqueda.selectedInfo, selectedInfoCombustible: this.busqueda.selectedInfoCombustible, selectedInfoCaja: this.busqueda.selectedInfoCaja
    })
      .pipe(
        catchError((error) => {
          console.error('Error al obtener los datos:', error);
          return of([]); 
        })
      )

      .subscribe((response: any) => {
        if (response && response.success === 'true') {
          this.busqueda.combustible = response.datos.retorno.map(
            (elemento: { tbtipomotor_descripcion: any }) =>
              elemento.tbtipomotor_descripcion);

          this.busqueda.valueCombustible = response.datos.retorno.map(
            (elemento: { tbtipomotor_codigo: any }) =>
              elemento.tbtipomotor_codigo
          );
        } else {
          console.error('La solicitud a la API no fue exitosa');
        }
      });
  }

  obtenerLinea() {
    this.busqueda.selectedValueFamilia = (<HTMLSelectElement>(
      document.getElementById('familia')
    )).value;
  
    this.busqueda.selectedInfoCaja = this.busqueda.valueCaja[this.busqueda.caja.indexOf(this.busqueda.selectedCombustibleValue)];
    this.busqueda.selectedCombustibleValue = (<HTMLSelectElement>(document.getElementById('combustible'))).value;
    this.busqueda.selectedInfoCombustible = this.busqueda.valueCombustible[this.busqueda.combustible.indexOf(this.busqueda.selectedCombustibleValue)];
     this.lineaService.getLinea({
      selectedInfo: this.busqueda.selectedInfo, selectedInfoCombustible: this.busqueda.selectedInfoCombustible, selectedInfoCaja: this.busqueda.selectedInfoCaja
    })
      .pipe(
        catchError((error) => {
          console.error('Error al obtener los datos:', error);
          return of([]); // Devuelve un observable vacío en caso de error
        })
      )

      .subscribe((response: any) => {
        if (response && response.success === 'true') {
          console.log(response);
        
          this.lineas = response.datos.retorno.map(


            (elemento: { linecome_descripfabricante: any }) =>
              elemento.linecome_descripfabricante);

          this.valueLinea = response.datos.retorno.map(
            (elemento: { linecome_codigo: any }) =>
              elemento.linecome_codigo
             
          );
        this.numeroLinea= this.valueLinea[0];
        this.nombreLinea= this.lineas[0];
        
          
        } else {
          console.error('La solicitud a la API no fue exitosa');
        }
      });
  }

  capturarNumeroLinea(){
    console.log(this.numeroLinea);
    
    this.router.navigate([
      '/repuestos',
      this.numeroLinea,
    ]);
  
  }  

  
  capturarDatos() {
   
    var seleccionBusqueda = (<HTMLSelectElement>(
      document.getElementById('seleccionLinea')
    )).value;

    this.inputBusqueda = (<HTMLInputElement>(
      document.getElementById('busqueda')
    )).value;

    if (seleccionBusqueda === 'vin') {
      this.busquedaService.getVin(this.inputBusqueda)
      .pipe(
        catchError((error) => {
          console.error("Error al obtener los datos:", error);
          return of([]); 
        })
      )
      .subscribe((response: any) => {
        if(response.datos.retorno.length===0){

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "No se encontró el VIN en nuestro sistema, revísalo e inténtalo nuevamente!",
           
          });
        }
        if (response && response.success === "true") {
          this.lineas = response.datos.retorno.map(


            (elemento: { linecome_codigo: any }) =>
              elemento.linecome_codigo);
            
          this.router.navigate([
                  '/repuestos',this.lineas[0]
  ]);
           
          
        } else {
          console.error("La solicitud a la API no fue exitosa");
        }
      });
    
      
    } else if (seleccionBusqueda === 'placa') {
      this.busquedaService.getPlaca(this.inputBusqueda)
      .pipe(
        catchError((error) => {
          console.error("Error al obtener los datos:", error);
          return of([]); // Devuelve un observable vacío en caso de error
        })
      )  
      .subscribe((response: any) => {
        if(response.datos.retorno.length===0){

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "No se encontró la Placa en nuestro sistema, revísalo e inténtalo nuevamente!",
          
          });
        }
        if (response && response.success === "true") {
          this.lineas = response.datos.retorno.map(


            (elemento: { linecome_codigo: any }) =>
              elemento.linecome_codigo);
            
          this.router.navigate([
                  '/repuestos',this.lineas[0]
  ]);
  
          
          
        } else {
          console.error("La solicitud a la API no fue exitosa");
        }
      });
          
        } else if (seleccionBusqueda === 'referencia') {
          this.busquedaService.getReferencia (this.inputBusqueda)
          .pipe(
              catchError(error => {
                console.error('Error al obtener los datos:', error);
                return of([]); // Devuelve un observable vacío en caso de error
              })
            )
            .subscribe((response: any) => {
          
              if(response.datos.referencia_nombre.length === 0){

                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "No se encontró el numero de referencia en nuestro sistema, revísalo e inténtalo nuevamente!",
                 
                });
              }
              if (response && response.success === 'true' && response.datos.referencia_nombre.length != 0) {      
                
                
                var datosBusquedaReferencia: {
                        opcionEleccion: string;
                        textoBusquedaReferencia: string;
                        codigo:string;
                      } = {
                        opcionEleccion: seleccionBusqueda,
                        textoBusquedaReferencia: this.inputBusqueda,
                        codigo: 'tv_03'
                      };
                
                      this.router.navigate([
                        '/repuesto',this.inputBusqueda
                        
                      ]);
                    }
               
               
           else {
                console.error('La solicitud a la API no fue exitosa');
                
              }
              
              
            });
            }

    
  }
  mostrarLinea() {
    this.form.reset();

    var seleccionlinea = (<HTMLSelectElement>(
      document.getElementById('seleccionLinea')
    )).value;
    var seccionLinea = <HTMLSelectElement>(
      document.getElementById('seccionlinea')
    );
    var seccionInputEntrada = <HTMLSelectElement>(
      document.getElementById('busqueda')
    );
    var seccionBtnSearch = <HTMLSelectElement>(
      document.getElementById('btn-search')
    );

    if (seleccionlinea === 'linea') {
      this.verLinea = true;
    } else {
      this.verLinea = false;
    }
  }
  
}


